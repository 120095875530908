<template>
  <div class="b-page b-page--home">

    <div class="is-recording-tag" 
      v-if="recordCurrentState == recordState.videoIsRecording && recordingTimer"> 
      <span class="is-recording-tag-dot"></span>
      <span class="is-recording-tag-title">
        Идет запись 
        <span>{{recordingTimer}}</span>
      </span>
    </div>

    <div class="videocontainer">
      <video id="ownvideobox" autoplay playsinline muted></video>
      <audio id="ownaudiobox" autoplay playsinline></audio>
    </div>

    <div class="record-actions" 
      v-if="recordCurrentState == recordState.videoIsReadyAndPaused || recordCurrentState == recordState.videoIsReadyAndPlaying">
      <div class="button-icon"  
        v-if="recordCurrentState == recordState.videoIsReadyAndPaused" 
        @click="playRecordedVideo()">
        <img :src="require(`../assets/images/confermall/play-video.svg`)"/>
      </div>

      <div class="button-icon" 
        v-if="recordCurrentState == recordState.videoIsReadyAndPlaying" 
        @click="pauseRecordedVideo()">
        <img :src="require(`../assets/images/confermall/pause-video.svg`)"/>
      </div>
    </div> 

    <div v-if="pluginHandle">

      <div class="control-buttons">
        <b-button v-b-tooltip.hover.top title="Начать запись" 
          v-if="recordCurrentState == recordState.noVideo" 
          @click="startRecording()">
          <img :src="require(`../assets/images/confermall/start-record.svg`)"/>
        </b-button>

        <b-button v-b-tooltip.hover.top title="Остановить запись" 
         v-if="recordCurrentState == recordState.videoIsRecording" 
          @click="endRecording()">
          <img :src="require(`../assets/images/confermall/stop-record.svg`)"/>
        </b-button>

        <b-button v-b-tooltip.hover.top title="Перезаписать видео" 
          v-if="recordCurrentState == recordState.videoIsReadyAndPaused || recordCurrentState == recordState.videoIsReadyAndPlaying"
          @click="startRecordingAgain()">
          <img :src="require(`../assets/images/confermall/restart-record.svg`)"/>
        </b-button>

        <b-button v-b-tooltip.hover.top title="Использовать видео" 
          v-if="recordReadyId && (recordCurrentState == recordState.videoIsReadyAndPaused || recordCurrentState == recordState.videoIsReadyAndPlaying)"
          @click="useVideo(recordReadyId)">
          <img :src="require(`../assets/images/confermall/use-video.svg`)"/>
        </b-button>
      </div>

    </div>

    <div v-if="isLoading"
      class="record-page-loader">
      <ConfermallLoader></ConfermallLoader>
    </div>

    <div v-if="showVideoAudioPermissionError" 
      class="record-page-loader"> 
      <div class="s-big-text-bold text-white px-3 text-center">
        Запись невозможна из-за настроек браузера. 
        <br/>
        Подключите камеру и микрофон.
      </div>
    </div>

  </div>
</template>

<script>
/* global __IS_PRODUCTION__*/
const IS_PRODUCTION = JSON.parse(__IS_PRODUCTION__);
import ConfermallLoader from "../components/ConfermallLoader.vue";

/* eslint-disable */
  const SEARCH_PARAMS = new URL(window.location.href).searchParams;
  if (SEARCH_PARAMS.get("recordid")) {
    var recordid = parseInt(SEARCH_PARAMS.get("recordid"));
    var RECORD_ID = recordid;
  }

  import { Janus } from 'janus-gateway';
  import { 
  createTokenForMediaServer,
  notifyRecordStartedOrStopped,
  useRecord
} from "../helpers/api";

  export default {
    name: 'RecordPage',
    components: {
      ConfermallLoader,
    },
    data(){
      return {
        pluginHandle: null,
        myjanus: null,
        server: "wss://jsc.webmoney.com/janus",
        opaqueId: "recordVideo-" + parseInt(Math.random(10) * 10000),

        recordCurrentState: 0,
        recordState: {
          noVideo: 0,
          videoIsRecording: 1,
          videoIsReadyAndPaused: 2,
          videoIsReadyAndPlaying: 3
        },
        isLoading: true,
        showVideoAudioPermissionError: false,
        localTracks: {},
        notificationRecord: {
          recordIsStarted: 1,
          recordIsStopped: 1,
        },
        recordCurrentSessionId: null,
        recordReadyId: null,
        recordingTimer: null,
        recordingTimerInterval: null
      }
    },
    mounted() {
      this.askVideoAndAudioPermissions()
    },
    methods:{
      askVideoAndAudioPermissions() {
        navigator.mediaDevices.getUserMedia({video: true, audio: true})
				.then((stream) => {
          let ownvideobox = document.querySelector("#ownvideobox");
          ownvideobox.srcObject = stream;

          this.initJanus()
				})
				.catch(() => {
          this.isLoading = false; 
          this.showVideoAudioPermissionError = true;
				});
      },
      initJanus() {
        Janus.init({
          debug: true,
          dependencies: Janus.useDefaultDependencies(),
          callback: () => {
            this.getToken()
          }
        });
      },
      getToken() {
        createTokenForMediaServer().then((resp) => {
          this.turnpw = resp.password;
          this.username = resp.tokenvalue;
          let options = {}
          options["username"] = this.username
          options["password"] = this.turnpw
          this.connectJanus(options);
        })
      },
      connectJanus(options) {
        let iceServers = [
          {url: 'turn:51.83.45.120:443?transport=tcp', username: options.username, credential: options.password},
        ]
        this.myjanus = new Janus({
          server: this.server,
					iceServers: iceServers,
          success: () =>{
            this.janusAttach()
          },
          error: (e) => {
            console.log("error: ", e)
          }
        })
      },
      janusAttach() {
        this.myjanus.attach({
          plugin: "janus.plugin.recordplay",
					opaqueId: this.opaqueId,
          success: (plugin) =>{
            this.pluginHandle = plugin;
            this.isLoading = false; 
          },
          onmessage: (msg, jsep) => {
            this.onMessageHandler(msg, jsep, this)
          },
          onlocaltrack: (track, on) => {
            this.onLocalTrackHandler(track, on, this.localTracks)
          },
          onremotetrack: (track, mid, on) => {
            this.onRemoteTrackHandler(track, mid, on, this)
          }
        })
      },
      onMessageHandler: (msg, jsep, scope) => {
        let result = msg["result"];

        if (result && result["status"]) {
          let event = result["status"];

          if (event === 'preparing' || event === 'refreshing') {
            scope.handleMessagePreparing(scope, jsep)    
          } else if (event === 'recording') {
            scope.handleMessageRecording(scope, result, jsep)    
          } else if (event === 'playing') {
            Janus.log("Playout has started!");
          } else if (event === 'stopped') {
            scope.handleMessageStoppedRecording(scope);
            
            setTimeout(() => {
              scope.recordCurrentState = scope.recordState.videoIsReadyAndPaused;
              scope.isLoading = false;
            }, 2000);
          }
        }
      },
      handleMessagePreparing: (scope, jsep) => {
        scope.pluginHandle.createAnswer(
          {
            jsep: jsep,
            tracks: [
              { type: 'data' }
            ],
            success: (jsep) => {
              let body = { request: "start" }
              scope.pluginHandle.send({ message: body, jsep: jsep });
            },
            error: function(error) {
              console.log("Error on create Answer handleMessagePreparing", error)	
            }
          }
        );
      },
      handleMessageRecording: (scope, result, jsep) => {
        if (jsep) {
          scope.pluginHandle.handleRemoteJsep({ jsep: jsep });
        }

        scope.recordCurrentSessionId = result["id"];

        if (IS_PRODUCTION) {
          notifyRecordStartedOrStopped(scope.recordCurrentSessionId, scope.notificationRecord.recordIsStarted, RECORD_ID).then((res) => {
            scope.recordReadyId = res.id
          })
          .catch((error) => {
            console.error("Error recordIsStarted: ", error);
          });
        }
      },
      handleMessageStoppedRecording: (scope) => {
        if (IS_PRODUCTION) {
          notifyRecordStartedOrStopped(scope.recordCurrentSessionId, scope.notificationRecord.recordIsStopped, RECORD_ID).then((res) => {
            scope.recordReadyId = res.id;
            scope.pluginHandle.hangup();
          })
          .catch((error) => {
            console.error("Error recordIsStopped: ", error);
          });
        }
      },
      onLocalTrackHandler: (track, on, localTracks) => {
        let trackId = track.id.replace(/[{}]/g, "");
        
        if (!on) {
          this.stopLocalTrackById(localTracks, trackId);
        }

        if (!localTracks[trackId]) {
          if (track.kind == "audio") {
            console.log("Audio track", trackId);
          } else {
            let stream = new MediaStream([track]);
            console.log("Video track ", stream);

            localTracks[trackId] = stream;
            let ownbox = document.querySelector("#ownvideobox");

            Janus.attachMediaStream(ownbox, stream);
          }
        } 
      },
      stopLocalTrackById(localTracks, trackId) {
        let stream = localTracks[trackId];
        if (stream) {
          try {
            let tracks = stream.getTracks();
            for (var i in tracks) {
              var track = tracks[i];
              if (track) {
                track.stop();
              }
            }
          } catch(e) {
            console.log(e);
          }
        }
      },
      onRemoteTrackHandler: (track, mid, on, scope) => {
        if (on) {
          if(track.kind === "audio") {
            let stream = new MediaStream([track]);
            let ownaudiobox = document.querySelector("#ownaudiobox");

            Janus.attachMediaStream(ownaudiobox, stream);
          } else {
            let stream = new MediaStream([track]);
            let ownbox = document.querySelector("#ownvideobox");

            Janus.attachMediaStream(ownbox, stream);
          }
        } else {
          scope.recordCurrentState = scope.recordState.videoIsReadyAndPaused;
        }
      },
      startRecording() {
        this.recordCurrentState = this.recordState.videoIsRecording;
        this.startRecordingTimer();

        this.sendVideoConfigJanus()
        this.createRecordOfferJanus()
      },
      startRecordingTimer() {
        var totalSeconds = 0;
        this.recordingTimerInterval = setInterval(() => {
          totalSeconds++;
          var seconds = this.pad(totalSeconds % 60);
          var minutes = this.pad(parseInt(totalSeconds / 60));
          this.recordingTimer = minutes + ":" + seconds
        }, 1000);
      },
      pad(val) {
        var valString = val + "";
        if (valString.length < 2) {
          return "0" + valString;
        } else {
          return valString;
        }
      },
      endRecording() {
        this.isLoading = true;
        this.stopRecordingTimer();

        let stop = { request: "stop" };
        this.pluginHandle.send({ message: stop });
        this.pluginHandle.hangup();
      },
      stopRecordingTimer() {
        this.recordingTimer = null;
        clearInterval(this.recordingTimerInterval);
      },
      sendVideoConfigJanus() {
        this.pluginHandle.send({
          message: {
            request: 'configure',
            'video-bitrate-max': 1024 * 1024,
            'video-keyframe-interval': 15000
          }
        });
      },
      createRecordOfferJanus() {
        this.pluginHandle.createOffer({
          tracks: [
            { type: 'audio', capture: true, recv: false },
            { type: 'video', capture: true, recv: false, simulcast: true },
          ],
          success: (jsep) => {
            let body = {
              request: "record", 
              name: "Record: " + RECORD_ID
            };
            this.pluginHandle.send({ message: body, jsep: jsep });
          }
        })
      },
      playRecordedVideo() {
        this.recordCurrentState = this.recordState.videoIsReadyAndPlaying;

        var play = { request: "play", id: parseInt(this.recordCurrentSessionId) };
        this.pluginHandle.send({ message: play });
      },
      pauseRecordedVideo() {
        this.recordCurrentState = this.recordState.videoIsReadyAndPaused;

        let pause = { request: "pause" };
        this.pluginHandle.send({ message: pause });
      },
      startRecordingAgain() {
        this.recordCurrentState = this.recordState.noVideo;   

        this.recordCurrentSessionId = null;
        this.askVideoAndAudioPermissions();
      }, 
      useVideo(recordid) {
        this.isLoading = true;

        useRecord(recordid).then((res) => {
          setTimeout(() => {
            window.parent.postMessage("videoResumeIsReady", "*");
            this.isLoading = false;
          }, 2000);
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error useRecord: ", error);
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "src/assets/styles/confermall/confermall-main.scss";

.is-recording-tag {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  width: 144px;
  height: 24px;
  padding: 0 8px;
  user-select: none;
  border-radius: 16px;
  background-color: $white;

  gap: 8px;
  &-dot {
    width: 8px;
    height: 8px;
    animation: blink 1s infinite;
    border-radius: 8px;
    background-color: $red-500;
  }
  &-title {
    color: $gray-500;

    @extend %sLabelSemi;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.record-actions {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  user-select: none;
  transform: translate3d(-50%, -50%, 0);
}

.record-page-loader {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: $gray-900;

  justify-content: center;
}

</style>
