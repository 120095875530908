<template>
  <div class="b-own-box" :class="{'is-mobile': isMobile}">

    <div class="b-own-box-top">

      <div class="b-room-info">
        <div class="b-own-box-top-text">
          Видеочат (Beta)
          <a href="https://video.web.money" class="videochat_link" target="_blank"> video.web.money</a>
        </div>

      </div>

      <div>
        <UsersVisibilitySwitcher @user-visibility-changed="onUserVisibilityChanged"></UsersVisibilitySwitcher>
      </div>

    </div>
    <div class="b-own-box-wrapper" v-if="usersInRoomVisible">
      <div class="b-own-box-bg" 
        :class="{'is-hidden': participants.length > 0, 'button-bottom': showConnectVideoChatButton || showAuthByWmButton}">
        <div class="b-own-box-bg-image"></div>

        <div class="b-room-occupants" 
          v-if="activeparticipants.length > 0 && !meJoinedChat">
          <div class="b-own-box-participant-list">
            <div class="b-own-box-participant"
              v-for="elem in activeparticipants"
              v-bind:key="elem['id']"
              :class="{'is-activated': elem.talking}">
              <div class="b-own-box-participant-content">

                <div class="b-own-box-participant-wrapper">

                  <div>
                    <div class="b-no-video">
                      <div class="b-no-video-avatar">
                        <UserBlock :id="elem['id']" :encryptedkey="elem.display"></UserBlock>
                      </div>
                    </div>
                  </div>
                  
                  <div class="b-own-box-participant-name">
                    <UserBlock :id="elem['id']" :encryptedkey="elem.display"></UserBlock>
                  </div>

                </div>
              </div>
            </div>
            <div class="b-room-occupants-blur"></div>
          </div>
        </div>

        <div class="b-room-occupants" 
          v-if="(activeparticipants.length == 0 && !meJoinedChat && showConnectVideoChatButton) || (meJoinedChat && participants.length == 0)">
          <div class="b-own-box-participant-list b-own-box-bg-empty-text align-content-center">
            В чате пока никого нет
          </div>
        </div>

        <div v-show="showConnectVideoChatButton">
          <a class="c-button c-button--connect"
            :class="{'is-loading': joinRoomLoader}"
            @click="joinToRoom()"
            href="#">
            <div class="b-own-box-top-online">
              <div v-if="userMe.encryptedKey">
                <UserBlock 
                  :userme="userMe.objectFromResponse" 
                  :authbtn="'true'" 
                  :id="'ownbox'" 
                  :encryptedkey="userMe.encryptedKey"
                  @user-rendered="onUserRendered"></UserBlock>
              </div>
            </div>
            Подключиться
          </a>
        </div>

        <div v-if="showAuthByWmButton">
          <a v-if="(isIAmSafari && !hasStorageAccess)" class="c-button c-button--connect" :class="{'is-loading': userAuthLoader}" @click="startAuthorizeProcessForSafari()" href="#">
            <i class="cni cni-video-camera"></i>
            Подключиться
          </a>
          <a v-if="(!isIAmSafari || hasStorageAccess)" class="c-button c-button--connect" :class="{'is-loading': userAuthLoader}" @click="startAuthorizeProcess()" href="#">
            <i class="cni cni-video-camera"></i>
            Подключиться
          </a>
        </div>

        <div class="c-loader" 
          v-if="!showConnectVideoChatButton && !showAuthByWmButton && !meJoinedChat"></div>
      </div>

      <div class="b-own-box-me" id="ownvideoboxcontainer"
        :class="{'is-visible': meJoinedChat}">
        <video class="b-own-box-me-video"
          autoplay
          playsinline
          id="ownvideobox"></video>
        <div class="b-no-video" 
          v-if="!userMe.hasVideo && userMe.encryptedKey && userMe.objectFromResponse">
          <div class="b-no-video-avatar">
            <UserBlock 
              :userme="userMe.objectFromResponse" 
              :id="'ownbox'" 
              :encryptedkey="userMe.encryptedKey"></UserBlock>
          </div>
        </div>
        <div class="b-own-box-me-text bg-gray-500 pointer"
          title="Наблюдатель"
          @click="toggleWatcherConnectPopup()"
          v-if="showIncognitoIcon">
          Я
          <i class="bi bi-incognito"></i>
        </div>

        <div class="b-own-box-me-text bg-warning pointer"
          title="В очереди"
          @click="toggleWaitingPopup()"
          v-if="showWaitingIcon">
          Я
          <i class="bi bi-hourglass-split"></i>
        </div>

        <div class="b-own-box-me-text"
          title="Спикер"
          v-if="!showIncognitoIcon && !showWaitingIcon">
          Я
        </div>

        <div class="b-own-box-me-actions">
          <div class="b-own-box-me-btn b-own-box-me-mute-audio" 
            v-if="!userMe.audioDisabled"
            @click="muteUnmuteMyMic()"
            :class="{'is-muted': !userMe.hasAudio}">
            <i class="cni cni-microphone"></i>
          </div>
          <div class="b-own-box-me-btn b-own-box-me-mute-video" 
            v-if="!userMe.videoDisabled"
            @click="muteUnmuteMyCam()"
            :class="{'is-muted': !userMe.hasVideo}">
            <i class="cni cni-video-camera"></i>
          </div>
          <div class="b-own-box-me-btn b-own-box-me-leave" 
            title="Покинуть чат"
            @click="leaveRoom()">
            <i class="cni cni-close"></i>
          </div>
        </div>

        <WatcherConnectRequestPopup v-if="showWatcherConnectPopup"
          :roomid="roomid"
          :sessionid="sessionid"
          @hide-watcher-connect-popup="onHideWatcherConnectPopup"></WatcherConnectRequestPopup>
      
        <WaitingQueuePopup v-if="showWaitingPopup"
          :sessionid="sessionid"
          @hide-waiting-popup="onHideWatcherConnectPopup"></WaitingQueuePopup>
      </div>


      <div class="b-own-box-participant-list">
        <div class="b-own-box-participant"
          v-for="elem in participants"
          v-bind:key="elem['id']"
          :class="{'is-activated': elem.talking}">
          <div class="b-own-box-participant-content">
            <div class="b-own-box-fullscreen" v-if="elem.hasVideo">
              <FullScreen :userid="elem['id']"></FullScreen>
            </div>

            <div class="b-own-box-participant-wrapper"
              v-bind:id="'videocontainer-' + elem['id']">
              <video class="b-own-box-participant-video"
                autoplay
                playsinline
                muted
                v-bind:id="'videobox-' + elem['id']"
                v-on:loadeddata="checkOrientation(elem)"
                :class="{'is-vertical': elem.vertical}"></video>
              <audio class="b-own-box-participant-audio"
                autoplay
                playsinline
                v-bind:id="'audiobox-' + elem['id']"></audio>

              <div v-if="!elem.hasVideo">
                <div class="b-no-video">
                  <div class="b-no-video-avatar">
                    <UserBlock :id="elem['id']" :encryptedkey="elem.display"></UserBlock>
                  </div>
                </div>
              </div>
              
              <div class="b-own-box-participant-name">
                <UserBlock :id="elem['id']" :encryptedkey="elem.display"></UserBlock>
              </div>
              <!-- <div id="scrsh">
                <ScreenShare :sfu="mysfutest"></ScreenShare>
              </div> -->
              <div class="b-not-publisher" v-if="!elem.hasVideo || !elem.hasAudio">
                <div class="b-not-publisher-item"  
                  title="Нет звука" 
                  v-if="!elem.hasAudio">
                  <i class="cni cni-microphone"></i>
                </div>
                <div class="b-not-publisher-item"  
                  title="Нет видео" 
                  v-if="!elem.hasVideo">
                  <i class="cni cni-video-camera"></i>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="b-modal-your-turn" v-if="showBecomeSpeakerBtn">
      <div class="b-modal-your-turn-overlay"></div>
      <div class="b-modal-your-turn-wrapper">
        <div class="b-modal-your-turn-window">
            <div class="b-modal-your-turn-header">
              Наступила Ваша очередь
            </div>
            <div class="b-modal-your-turn-descr">
              Подключиться к беседе?
            </div>
            <div class="b-modal-your-turn-small">
              Вы можете подключиться в течение {{countDownSeconds}} секунд.
            </div>
            <div>
              <div class="c-button"
                @click="becomeSpeaker()">
                Стать спикером
              </div>
            </div>
        </div>
      </div>  
    </div>

    <div v-if="showEventsChat && eventsChatId && meJoinedChat">
      <EventsChat :eventsid="eventsChatId"></EventsChat>
    </div>
  </div>
</template>

<script>

/* global __BASE_URL__ */
/* global __HOSTNAME_API__ */
/* global __QUERY_KEYS__*/
/* global __VALIDATE_TOKEN_URL__*/
/* global __SOCKET_URL__*/
const SOCKET_URL = __SOCKET_URL__;
const BASE_URL = __BASE_URL__;
const HOSTNAME_API = JSON.parse(__HOSTNAME_API__);
const QUERY_KEYS = __QUERY_KEYS__;
const VALIDATE_TOKEN_URL = __VALIDATE_TOKEN_URL__;
const JANUS_URL = "wss://jsc.webmoney.com/janus";

var API_URL = BASE_URL;

if (HOSTNAME_API[window.location.hostname]) {
  API_URL = HOSTNAME_API[window.location.hostname];
}

import UsersVisibilitySwitcher from "./UsersVisibilitySwitcher.vue";
import UserBlock from "./UserBlock.vue";
import UserBlockToast from "./UserBlockToast.vue";
import FullScreen from "./FullScreen.vue";
// import ScreenShare from "./ScreenShare.vue";
import { Janus } from "janus-gateway";
import { io } from "socket.io-client";
import { 
  getAuthValidateToken, 
  createTokenForMediaServer,
  allKeysExistsInCookies,
  saveLoginInfotoDB
} from "../helpers/api";
import { 
  getRoomOccupants, 
  getUsersQueuePosition,
} from "../helpers/api";
import WatcherConnectRequestPopup from "./WatcherConnectRequestPopup.vue";
import WaitingQueuePopup from "./WaitingQueuePopup.vue";
import EventsChat from "./EventsChat.vue";
var sfutest = null;
var myid = null;
var mypvtid = null;
var use_msid = false;
var feedStreams = {};
var feeds = {};
var subscriptions = {};
var opaqueId = "videoroom-" + Janus.randomString(12);
var slots = {};
var mids = {};
var myroom = 124566;
var eventsChatId = "00fe53d1-ef97-4db9-b3a2-14c22765da5b";
var subStreams = {};
var remoteFeed = null;
var remoteTracks = {};
var streamsIAlreadySubscribedTo = [];
var aleradyJoinedAtFirstTime = false;

const SEARCH_PARAMS = new URL(window.location.href).searchParams;

if (SEARCH_PARAMS.get("roomid")) {
  myroom = parseInt(SEARCH_PARAMS.get("roomid"));
}

if (SEARCH_PARAMS.get("eventsid")) {
  eventsChatId = SEARCH_PARAMS.get("eventsid");
}

export default {
  name: "OwnBox",
  components: {
    UsersVisibilitySwitcher,
    UserBlock,
    FullScreen,
    WatcherConnectRequestPopup,
    WaitingQueuePopup,
    EventsChat
    // ScreenShare
  },
  props: {
    msg: String
  },
  data() {
    return {
      activeparticipants: [],
      username: "sOxdYf2fu6Ic4/+btmiFBDIKcv/7lqA=--rgnTCSpZGIlXvuLq--iUCzI9Y3y928s3V1tHSCSQ==",
      feeds: {},
      feedStreams: {},
      subStreams: {},
      slots: {},
      mids: {},
      useLocalAudio: true,
      useLocalVideo: true,
      subscriptions: {},
      participants: [],
      currentUser: null,
      userAuthLoader: false,
      joinRoomLoader: false,
      userSignedInByWebMoney: false,
      showAuthByWmButton: false,
      showConnectVideoChatButton: false,
      tokeForMediaServer: null,
      meJoinedChat: false,
      turnpw: null,
      myJanus: null,
      mysfutest: null,
      userMe: {
        hasVideo: false,
        hasAudio: false,
        videoDisabled: true,
        audioDisabled: true,
        encryptedKey: null,
        objectFromResponse: null
      },
      speakers: [],
      showSpeakersBox: false,
      maxSpeakersSize: 100,
      showBecomeSpeakerBtn: false,
      becomeSpeakerProcess: false,
      countDownSeconds: 30,
      showWatcherConnectPopup: false,
      showIncognitoIcon: false,
      roomid: myroom,
      eventsChatId: eventsChatId,
      showEventsChat: false,
      sessionid: null,
      showWaitingIcon: false,
      showWaitingPopup: false,
      joinRoomAfterAuth: false,
      usersInRoomVisible: true,
      isMobile: false,
      userStreams: {},
      ownStreams: {},
      isIAmSafari: false,
      hasStorageAccess: false,
      myid: null
    };
  },
  mounted() {
    this.isMobile = this.$device.mobile;
    this.showEventsChat = !this.$device.mobile;
    
    this.checkSafariAndStorageAccess();
    this.getOccupantsAndCheckThemOnInterval(myroom);
    this.initJanus();
  },
  methods: {
    checkSafariAndStorageAccess() {
      this.isIAmSafari = navigator.vendor && navigator.vendor.indexOf("Apple") > -1 && navigator.userAgent && navigator.userAgent.indexOf("CriOS") == -1 && navigator.userAgent.indexOf("FxiOS") == -1;

      if (this.isIAmSafari) {
        document.hasStorageAccess().then((res) => {
          this.hasStorageAccess = res;
        });
      }
    },
    getOccupantsAndCheckThemOnInterval(roomId) {
      this.getOccupants(roomId);

      var interval = setInterval(() => {
        if (this.meJoinedChat) {
          clearInterval(interval);
        } else {
          this.getOccupants(roomId);
        }
      }, 15000);
    },
    initJanus() {
      Janus.init({
        debug: true,
        dependencies: Janus.useDefaultDependencies(),
        callback: () => {
          getAuthValidateToken().then(() => {
            this.userSignedInByWebMoney = true;
            this.showUserInfo();
          }).catch(() => {
            this.userAuthLoader = false;
            this.showAuthByWmButton = true;
          });
        }
      });
    },
    muteVideoWM() {
      let tracks = [];
      this.plugin.createOffer({
        iceRestart: true,
        tracks: tracks,
        success: (jsep) => {
          this.plugin.send({ message: { request: "configure", video: false }, jsep: jsep });
        },
        error: (error) => {console.log("ERROR", error);}
      });
    },
    unmuteVideoWM() {
      let tracks = [];
      tracks.push({ type: "video", capture: true, recv: true, simulcast: true });

      this.plugin.createOffer({
        iceRestart: true,
        tracks: tracks,
        success: (jsep) => {
          this.plugin.send({ message: { request: "configure", video: true }, jsep: jsep });
        },
        error: (error) => {console.log("ERROR", error);}
      });
    },
    muteUnmuteMyCam() {
      if (this.plugin.isVideoMuted()) {
        this.unmuteVideoWM();
        this.plugin.unmuteVideo();
        this.userMe.hasVideo = true;
      } else {
        this.muteVideoWM();
        this.plugin.muteVideo();
        this.userMe.hasVideo = false;
      }
    },
    muteUnmuteMyMic() {
      if(this.plugin.isAudioMuted()) {
        this.plugin.unmuteAudio();
        this.userMe.hasAudio = true;
      }else {
        this.plugin.muteAudio();
        this.userMe.hasAudio = false;
      }
    },
    saveLoginData(id,seseionid,token) {
      console.log(SOCKET_URL);
      console.log(io);
      let data = {};
      data["token"] = token;
      data["sessionid"] = seseionid;
      data["userid"] = id;
      saveLoginInfotoDB(data);
      this.sessionid = seseionid;
    },
    attachSuccess() {
      let userid = this.plugin.id;
      let sessionid = this.plugin.session.getSessionId();
      let token = this.plugin.token;
      this.saveLoginData(userid,sessionid,token);

      if (this.joinRoomAfterAuth) {
        this.joinToRoom();
        this.joinRoomAfterAuth = false;
      } else {
        this.showConnectVideoChatButton = true;
      }
    },
    subscribeTo(sources) {
      let streamsToSubscribe = [];

      for (let s in sources) {
        let streams = sources[s];

        for (let i in streams) {
          let stream = streams[i];
          streamsToSubscribe.push(stream);
        }
      }

      this.janus.attach(
        {
          plugin: "janus.plugin.videoroom",
          opaqueId: opaqueId,
          success: (pluginHandle) => {
            let actionWithSubscription = "create";
            
            if (!remoteFeed) {
              remoteFeed = pluginHandle;
            }
            
            let subscription = [];
            let added = null, removed = null;

            for (let i in streamsToSubscribe) {
              let stream = streamsToSubscribe[i];
              Janus.log("Subscribed to " + stream.id + "/" + stream.mid + "?", subscriptions);

              let streamIdMid = `${stream.id}_${stream.mid}`;

              if (streamsIAlreadySubscribedTo.indexOf(streamIdMid) == -1) {
                streamsIAlreadySubscribedTo.push(streamIdMid);

                if (!feedStreams[stream.id].slot) {
                  let slot;

                  for (let i = 1; i < 6; i++) {
                    if (!feeds[i]) {
                      slot = i;
                      feeds[slot] = stream.id;
                      feedStreams[stream.id].slot = slot;
                      feedStreams[stream.id].remoteVideos = 0;
                      break;
                    }
                  }
                }

                subscription.push({
                  feed: stream.id,
                  mid: stream.mid	
                });

                if (!subscriptions[stream.id]) {
                  subscriptions[stream.id] = {};
                  subscriptions[stream.id][stream.mid] = true;
                }
              } else {
                actionWithSubscription = "update";

                if (stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                  (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
                  continue;
                }

                if (stream.disabled) {
                  Janus.log("Disabled stream:", stream);

                  if (!removed) {
                    removed = [];
                  }
                  removed.push({
                    feed: stream.id,
                    mid: stream.mid
                  });
                  delete subscriptions[stream.id][stream.mid];
                  continue;
                }

                if (subscriptions[stream.id] && subscriptions[stream.id][stream.mid]) {
                  Janus.log("Already subscribed to stream, skipping:", stream);
                  continue;
                }

                if (!feedStreams[stream.id].slot) {
                  let slot;

                  for (let i = 1; i < 6; i++) {
                    if (!feeds[i]) {
                      slot = i;
                      feeds[slot] = stream.id;
                      feedStreams[stream.id].slot = slot;
                      feedStreams[stream.id].remoteVideos = 0;
                      break;
                    }
                  }
                }

                if (!added) {
                  added = [];
                }

                added.push({
                  feed: stream.id,
                  mid: stream.mid
                });

                if (!subscriptions[stream.id]){
                  subscriptions[stream.id] = {};
                }

                subscriptions[stream.id][stream.mid] = true;
              }
            }

            if (actionWithSubscription == "create") {
              remoteTracks = {};
              Janus.log("  -- This is a multistream subscriber");

              let subscribe = {
                request: "join",
                room: myroom,
                ptype: "subscriber",
                streams: subscription,
                use_msid: use_msid,
                private_id: mypvtid
              };

              if (aleradyJoinedAtFirstTime) {
                subscribe = {
                  request: "subscribe",
                  streams: subscription
                };
              }

              aleradyJoinedAtFirstTime = true;
              remoteFeed.send({ message: subscribe });
            } else if (actionWithSubscription == "update") {
              if ((!added || added.length === 0) && (!removed || removed.length === 0)) {
                return;
              }

              let update = { request: "update" };

              if (added){
                update.subscribe = added;
              }

              if (removed){
                update.unsubscribe = removed;
              }
              
              remoteFeed.send({ message: update });
            }
          },
          error: (error) => {
            console.error(error);
          },
          iceState: (state) => {
            Janus.log("ICE state (remote feed) changed to " + state);
          },
          webrtcState: (on) => {
            Janus.log("Janus says this WebRTC PeerConnection (remote feed) is " + (on ? "up" : "down") + " now");
          },
          slowLink: (uplink, lost, mid) => {
            Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
              " packets on mid " + mid + " (" + lost + " lost packets)");
          },
          onmessage: (msg, jsep) => {
            Janus.debug(" ::: Got a message (subscriber) :::", msg);
            let event = msg["videoroom"];
            Janus.debug("Event: " + event);

            if (msg["error"]) {
              console.error(msg);
            } else if (event) {
              if (event === "attached") {
                Janus.log("Successfully attached to feed in room " + msg["room"]);
              } else if (event === "event") {
                let mid = msg["mid"];
                let substream = msg["substream"];
                let temporal = msg["temporal"];

                if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                  let sub = subStreams[mid];
                  let feed = feedStreams[sub.feed_id];
                  let slot = slots[mid];
                  console.log(feed);
                  console.log(slot);
                }
              } 
            }

            if (msg["streams"]) {
              for (let i in msg["streams"]) {
                let mid = msg["streams"][i]["mid"];
                subStreams[mid] = msg["streams"][i];
                let feed = feedStreams[msg["streams"][i]["feed_id"]];

                if (feed && feed.slot) {
                  slots[mid] = feed.slot;
                  mids[feed.slot] = mid;
                }
              }
            }

            if (jsep) {
              Janus.debug("Handling SDP as well...", jsep);
              remoteFeed.createAnswer(
                {
                  jsep: jsep,
                  tracks: [
                    { type: "data" }
                  ],
                  success: (jsep) => {
                    Janus.debug("Got SDP!");
                    Janus.debug(jsep);
                    let body = { request: "start", room: myroom };
                    remoteFeed.send({ message: body, jsep: jsep });
                  },
                  error: (error) => {
                    Janus.error("WebRTC error:", error);
                  }
                });
            }
          },
          onlocaltrack: (track, on) => {
            console.log(track);
            console.log(on);
          },
          onstreamadded: () => {
            alert("remote track addd");
          },
          onremotetrack: (track, mid, on) => {
            console.log("onremotetrack: ", track, mid, on);
            console.log("Slots: ", slots);
            let objIndex = null;
            let slot = slots[mid];
            let sub = subStreams[mid];
            let feed = feedStreams[sub.feed_id];

            if(feed){
              for(let obji in this.participants ){
                let objUser = this.participants[obji];

                if(objUser && objUser["id"] && feed){
                  if(objUser["id"] == feed["id"]){
                    objIndex = obji;
                  }
                }
              }
            }
            console.log("-----  objIndex", objIndex);

            if(!on){
              delete remoteTracks[mid];
              delete slots[mid];

              if(feed && objIndex){
                let remotevideobox = document.querySelector("#videobox-" + feed["id"]);
                let sourceobj = remotevideobox.srcObject;
                let existingtrack = sourceobj.getVideoTracks()[0];
                let objuser = this.participants[objIndex];
                if(existingtrack){
                  objuser.hasVideo = false;
                  existingtrack.stop();
                }
              }
              return;
            }
        

            Janus.debug(" >> This track is coming from feed " + sub.feed_id + ":", feed);

            if (feed && !slot) {
              slot = feed.slot;
              slots[mid] = feed.slot;
              mids[feed.slot] = mid;
            }
            Janus.debug(" >> mid " + mid + " is in slot " + slot);

            if (feed && !on) {
              if (track.kind === "audio") {
                if (this.participants[objIndex]) {
                  this.participants[objIndex].hasAudio = false;
                }
              } else {
                if (this.participants[objIndex]) {
                  this.participants[objIndex].hasVideo = false;
                }
              }

              delete remoteTracks[mid];
              delete slots[mid];
              delete mids[slot];
              return;
            }

            if(!this.userStreams[feed.id]){
              this.userStreams[feed.id] = {};
            }

            if (track.kind === "audio") {
              let stream = new MediaStream([track]);
              window.remoteAudio = stream;
              remoteTracks[mid] = stream;
              Janus.log("Created remote audio stream:", stream);

              this.userStreams[feed.id]["audio"] = stream;
              setTimeout(() => {
                this.attachTrackToBox(feed["id"],"audio");
              }, 500);

              if (feed.remoteVideos === 0) {
                console.log("No remote videos");
              }
              this.participants[objIndex].hasAudio = true;
              this.participants[objIndex].audioDisabled = false;
            } else {
              feed.remoteVideos++;

              track.onmute = () => {
                setTimeout(()=> {
                  this.hideVideoContainerOnMute(feed["id"]);
                }, 500);
              };

              track.onunmute = () => {
                this.showVideoContainerOnUnmute(feed["id"]);
              };
              
              let stream = new MediaStream([track]);
              this.userStreams[feed.id]["video"] = stream;
              remoteTracks[mid] = stream;

              setTimeout(() => {
                this.attachTrackToBox(feed["id"],"video");
              }, 500);

              this.participants[objIndex].hasVideo = true;
              this.participants[objIndex].videoDisabled = false;
            }
          },
          oncleanup: () => {
            Janus.log(" ::: Got a cleanup notification (remote feed) :::");

            for (let i = 1; i < 6; i++) {
              feedStreams[i].simulcastStarted = false;
              feedStreams[i].remoteVideos = 0;
            }
            remoteTracks = {};
          }
        });
    },
    unsubscribeFrom(id) {
      let feed = feedStreams[id];
      if(!feed)
        return;
      Janus.debug("Feed " + id + " (" + feed.display + ") has left the room, detaching");

      delete feeds[feed.slot];
      feeds.slot = 0;
      delete feedStreams[id];
      let unsubscribe = {
        request: "unsubscribe",
        streams: [{ feed: id }]
      };
      if(remoteFeed != null)
        remoteFeed.send({ message: unsubscribe });
      delete subscriptions[id];
    },
    publishOwnFeed(useAudio,useVideo) {
      console.log("PUBL");
      this.useLocalAudio = useAudio;
      this.useLocalVideo = useVideo;
      let tracks = [];

      if(useAudio) {
        tracks.push({ type: "audio", capture: true, recv: false });
      }else{
        tracks.push({ type: "audio", capture: false, recv: false });
      }

      if(useVideo){
        tracks.push({ type: "video", capture: true, recv: false, simulcast: true });
      }else{
        tracks.push({ type: "video", capture: false, recv: false, simulcast: true });
      }
      
      if(!useVideo && !useAudio){
        tracks = [ { type: "data" }];
      }
 
      sfutest.createOffer({
        iceRestart: true,
        tracks: tracks,
        video: "lowres",
        success: (jsep) => {
          Janus.debug(jsep);
          let publish = { request: "configure", audio: useAudio, video: useVideo };
          console.log(publish);
          sfutest.send({ message: publish, jsep: jsep });
        },
        error: (error) => {
          console.log(error);
          Janus.error("WebRTC error:", error);

          if (useAudio) {
            console.log("USEAUDIO");
          }

          if(useAudio && useVideo){
            this.publishOwnFeed(true,false);
          }

          if(useAudio && !useVideo){
            this.publishOwnFeed(false,true);
          }

          if(!useAudio && !useAudio){
            this.publishOwnFeed(false,false);
          }
        }
      });
    },
    addParticipant(newpart, showToast) {
      console.log("------------NEWPART", newpart);

      let allids = [];

      for (let prt in this.participants) {
        allids.push(this.participants[prt]["id"]);
      }

      if (allids.indexOf(newpart["id"]) == -1) {
        newpart.hasAudio = false;
        newpart.hasVideo = false;
        newpart.vertical = false;

        this.participants.push(newpart);

        if (newpart["id"] && newpart["display"]) {
          if (showToast) {
            let userBlockToastContent = {
              component: UserBlockToast,
              props: {
                userid: newpart["id"],
                userdisplay: newpart["display"],
                text: "присоединился к чату"
              }
            };

            this.$toast.success(userBlockToastContent);
          }
        }
      }
    },
    eventProcessor(message) {
      let evt = message["videoroom"];
      console.log("MESSAGEeventProcessoreventProcessor", message);

      if (evt == "joined") {
        console.log("Joined", message);
        myid = message["id"];
        this.myid = message["id"];
        mypvtid = message["private_id"];
        this.publishOwnFeed(true,true);

        if (message["publishers"]) {
          console.log("PUBLISHERS: ", message["publishers"]);

          if(message["publishers"].length > 0){
            this.addUsersToDiscussion(message["publishers"]);
          }
        }

        if(message["attendees"]){
          this.addUsersToDiscussion(message["attendees"]);
        }

        this.meJoinedChat = true;
        setTimeout(() => this.getOccupants(myroom), 200);
      } else if (evt == "destroyed") {
        window.location.reload();
      } else if (evt == "event") {
        if (message["streams"]) {
          let streams = message["streams"];
          
          for (let i in streams) {
            let stream = streams[i];
            stream["id"] = this.myid;
            stream["display"] = this.myusername;
          }
          feedStreams[myid] = {
            id: this.myid,
            display: this.myusername,
            streams: streams
          };
        } else if (message["publishers"]) {
          this.addUsersToDiscussion(message["publishers"]);
        } else if (message["leaving"]) {
          console.log("Leaving");
        } else if (message["unpublished"]) {
          let unpublishid = message["unpublished"];
          this.unsubscribeFrom(unpublishid);
          console.log(message);
          let peerid = message["unpublished"];
          console.log("PARTICIPANTS: ", this.participants, peerid);
          var result = this.participants.filter(obj => {
            return obj.id === peerid;
          });
          
          if (result[0] && result[0]["id"] && result[0]["display"]) {
            let userBlockToastContent = {
              component: UserBlockToast,
              props: {
                userid: result[0]["id"],
                userdisplay: result[0]["display"],
                text: "покинул чат"
              }
            };

            this.$toast.info(userBlockToastContent);

            let unpubindex = this.participants.indexOf(result[0]);

            if (unpubindex > -1) {
              this.participants.splice(unpubindex, 1);
            }
          }
        } else if (message["joining"]) {
          this.addParticipant(message["joining"], true);
        }
      }
    },
    jsepProcessor(jsep) {
      console.log(jsep);
      Janus.debug("Handling SDP as well...", jsep);
      console.log("JSEPPPP: ", sfutest);
      sfutest.handleRemoteJsep({ jsep: jsep });
    },
    authorizeByWebMoney() {
      this.userAuthLoader = true;

      var win = window.open(`${API_URL}${VALIDATE_TOKEN_URL}`, "_blank", "width=700,height=600");

      var count = 60;
      this.checkUserInterval = setInterval(() => {
        if (allKeysExistsInCookies(QUERY_KEYS)) {
          clearInterval(this.checkUserInterval);

          getAuthValidateToken().then(() => {
            this.userSignedInByWebMoney = true;
            this.joinRoomAfterAuth = true;

            this.showUserInfo();
          }).catch((error) => {
            this.userAuthLoader = false;
            this.showAuthByWmButton = true;
            console.error(error);
          });
        } else {
          if (count == 0 || win.closed) {
            clearInterval(this.checkUserInterval);
            this.userAuthLoader = false;
          }
        }

        count--;
      }, 1000);
    },
    startAuthorizeProcessForSafari(){
      document.requestStorageAccess().then(() => {
        this.authorizeByWebMoney();
      },(error) => {
        console.error("Error - ", error );
      });
    },
    startAuthorizeProcess() {
      this.authorizeByWebMoney();
    },
    joinToRoom() {
      this.joinRoomLoader = true;

      let registerdata = {
        request: "join",
        room: myroom,
        ptype: "publisher",
        display: this.userMe.encryptedKey
      };

      sfutest.send({ message: registerdata });
    },
    attachJanus() {
      this.janus.attach(
        {
          plugin: "janus.plugin.videoroom",
          opaqueId: opaqueId,
          success: (plugin) => {
            this.plugin = plugin;
            sfutest = plugin;
            window.mysfu = sfutest;
            this.mysfutest = plugin;
            this.attachSuccess();
          },
          error: (error) => {
            this.onError("Error attaching plugin... ", error);
          },
          onmessage: (msg, jesp) => {
            console.log("MESSAGEMessage:", msg);
            this.showConnectVideoChatButton = false;
            this.joinRoomLoader = false;
            let event = msg["videoroom"];

            if (event != undefined && event != null) {
              this.eventProcessor(msg);
            }

            if (jesp) {
              this.jsepProcessor(jesp);
            }
          },
          iceState: (state) => {
            console.log("ICE state changed to " + state);
          },
          webrtcState: (on) => {
            Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");

            if (!on) {
              return;
            }
          },
          onlocaltrack: (track, on) => {
            if(!on){
              console.warn("not on");
            }

            if (track.kind === "video") {
              let stream = new MediaStream([track]);
              this.ownStreams["video"] = stream;
              let ownbox = document.querySelector("#ownvideobox");
              Janus.attachMediaStream(ownbox, stream);
              this.userMe.hasVideo = true;
              this.userMe.videoDisabled = false;
            }

            if (track.kind === "audio") {
              this.userMe.hasAudio = true;
              this.userMe.audioDisabled = false;
            }
          },
          oncleanup: () => {
            if (this.becomeSpeakerProcess) {
              this.attachJanus();
            }
          },
          onremotetrack: (track, mid, on) => {
            console.log(track, mid, on);
          },      
          mediaState: (medium, on, mid) => {
            Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
          },
        }
      );
    },
    connectJanus(options) {
      this.janus = new Janus({
        server: options.server,
        iceServers:  [
          {url: "turn:51.83.45.120:443?transport=tcp", username: options.token, credential: options.turnpw},
        ],
        token: options.token,
        success: () => {
          this.myJanus = this.janus;
          this.attachJanus();
        },
        error: (error) => {
          this.onError("Failed to connect to janus server", error);
        },
        destroyed: () => {
          window.location.reload();
        }
      });
    },
    updateStreamsList() {
      this.plugin.send({
        message: { request: "list" },
        success: (result) => {
          if (!result) {
            this.onError("Got no response to our query for available streams");
          }
          this.streamList.options = result.list;

          if (result.list.length) {
            this.streamList.selected = this.streamList.options[0].id;
          }
        }
      });
    },
    onError(message, error = "") {
      Janus.error(message, error);
      this.error = message + error;
    },
    connectToMediaServer(token,pw) {
      var connectOptions = {
        server: JANUS_URL,
        token: token,
        turnpw: pw
      };

      this.connectJanus(connectOptions);
    },
    showUserInfo() {
      createTokenForMediaServer().then((resp) => {
        console.log("TURN: ", resp);
        this.turnpw = resp.password;
        this.tokeForMediaServer = resp.tokenvalue;
        this.userMe.encryptedKey = resp.encryptedkey;
      }).catch((err) => {
        this.userAuthLoader = false;
        console.error(err);
      });
    },
    onUserRendered(data) {
      console.log(data);
      this.userAuthLoader = false;
      this.showAuthByWmButton = false;
      this.userMe.objectFromResponse = data.user;

      this.connectToMediaServer(this.tokeForMediaServer,this.turnpw);
    },
    leaveRoom() {
      window.location.reload();
    },
    hideVideoContainerOnMute(id){
      for(let partind in this.participants){
        if(this.participants[partind].id == id){
          this.participants[partind].hasVideo = false;
        }
      }
    },
    showVideoContainerOnUnmute(id){
      for(let partind in this.participants){
        if(this.participants[partind].id == id){
          this.participants[partind].hasVideo = true;
        }
      }
    },
    addUsersToDiscussion(users) {
      let list = users;
      Janus.debug("Got a list of available publishers/feeds:", list);
      let participantsStreams = [];

      console.log("2 list list", list);

      for (let f in list) {
        console.log("LIST PARTICIPANTS: ", list[f]);

        this.addParticipant(list[f], false);

        if (list[f]["dummy"]) {
          continue;
        }
        let id = list[f]["id"];
        let display = list[f]["display"];
        let streams = list[f]["streams"];

        for (let i in streams) {
          let stream = streams[i];
          stream["id"] = id;
          stream["display"] = display;
        }
        let slot = feedStreams[id] ? feedStreams[id].slot : null;
        let remoteVideos = feedStreams[id] ? feedStreams[id].remoteVideos : 0;
        feedStreams[id] = {
          id: id,
          display: display,
          streams: streams,
          slot: slot,
          remoteVideos: remoteVideos
        };
        Janus.debug("  >> [" + id + "] " + display + ":", streams);

        if (streams) {
          participantsStreams.push(streams);
        }
      }

      if (participantsStreams.length > 0) {
        this.subscribeTo(participantsStreams);
      }
    },
    getOccupants(myroom) {
      getRoomOccupants(myroom).then((res) => {
        this.activeparticipants = res["plugindata"]["data"]["participants"] || [];
        this.speakers = this.activeparticipants.filter((obj) => obj.publisher == true);

        if (this.speakers.length > 0 && this.speakers.length == this.maxSpeakersSize) {
          if (this.speakers.filter(e => e.display == this.userMe.encryptedKey).length == 0) {
            if (this.sessionid) {
              this.getUsersQueuePos();
            }
          }
        }
      }).catch((er) => {
        console.error(er);
      });
    },
    becomeSpeaker() {
      this.becomeSpeakerProcess = true;
      this.plugin.detach();
    },
    toggleWatcherConnectPopup() {
      if (this.showWatcherConnectPopup) {
        this.showWatcherConnectPopup = false;
      } else {
        this.showWatcherConnectPopup = true;
      }
    },
    toggleWaitingPopup() {
      if (this.showWaitingPopup) {
        this.showWaitingPopup = false;
      } else {
        this.showWaitingPopup = true;
      }
    },
    onHideWatcherConnectPopup() {
      this.getUsersQueuePos();
    },
    getUsersQueuePos() {
      getUsersQueuePosition(this.sessionid).then((res) => {
        if (res.position == 0) {
          this.showIncognitoIcon = true;
          this.showWatcherConnectPopup = true;

          this.showWaitingIcon = false;
          this.showWaitingPopup = false;
        } else {
          this.showIncognitoIcon = false;
          this.showWatcherConnectPopup = false;

          this.showWaitingIcon = true;
          this.showWaitingPopup = true;
        }
      }).catch((er) => {
        console.error(er);
      });
    },
    onUserVisibilityChanged(usersVisible) {
      this.usersInRoomVisible = usersVisible;

      if(usersVisible){
        let onlineusers = Object.keys(this.userStreams);

        for(let id in onlineusers){
          setTimeout(() => {
            this.attachStreamToVideoBox(onlineusers[id]);
          }, 500);
        }
        setTimeout(() => {
          this.attachOwnBoxVideoAudio();
        }, 500);
      }
    },
    attachOwnBoxVideoAudio(){
      let ownbox = document.querySelector("#ownvideobox");

      if(this.ownStreams["video"]){
        if(ownbox){
          Janus.attachMediaStream(ownbox, this.ownStreams["video"]);
        }
      }
    },
    attachStreamToVideoBox(id){
      this.attachTrackToBox(id,"audio");
      this.attachTrackToBox(id,"video");
    },

    attachTrackToBox(id,type){
      let remotebox = null;

      if(type=="video"){
        remotebox = document.querySelector("#videobox-" + id);
      }

      if(type=="audio"){
        remotebox = document.querySelector("#audiobox-" + id);
      }
      if(remotebox){
        console.log(remotebox);

        setTimeout(() => {
          Janus.attachMediaStream(remotebox, this.userStreams[id][type]);
        },300);
      }
    },
    checkOrientation(elem) {
      var video = document.getElementById("videobox-" + elem["id"]);

      if (video) {
        if(video.videoHeight > video.videoWidth) {
          elem.vertical = true;
        } else {
          elem.vertical = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#ownvideobox {
  -webkit-transform: scale(-1, 1);
     -moz-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
       -o-transform: scale(-1, 1);
          transform: scale(-1, 1);
}
.b-own-box-bg .c-button {
  font-size: 18px;
  position: relative;
  z-index: 99;
  display: inline-flex;
  align-items: center;
  padding: 16px 32px;
  box-shadow: var(--box-shadow);
  .cni {
    font-size: 24px;
  }
}

.b-no-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  color: var(--no-video-color);
  background-color: var(--no-video-bg);

  justify-content: center;
}

.b-own-box {
  display: grid;
  height: 100%;
  padding: 16px;

  grid-template-rows: 1fr max-content;

  &-wrapper {
    position: relative;
    margin-top: 40px;
    @media (max-width: 575px) {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      height: 100%;
      margin-top: 0;
      padding-top: 40px;
    }
  }
  &-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;

    justify-content: center;
    &.button-bottom {
      @media (max-width: 575px) {
        align-items: flex-end;
      }
    }
    &.is-hidden {
      display: none;
    }
    &-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.1;
      background: transparent url("../assets/images/bg-video.svg") center center no-repeat;
      background-size: 60%;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        content: "";

        backdrop-filter: grayscale(0.3);
      }
    }
    &-empty-text {
      font: 400 18px/24px var(--font-family);
      color: var(--no-participants-txt-color);
      @media (max-width: 575px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }
  &-top {
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 35px;
    padding: 8px 10px;
    border: 1px solid var(--chat-border-color);
    background-color: var(--own-box-top-bg);
    box-shadow: var(--box-shadow);

    backdrop-filter: blur(8px);
    justify-content: space-between;

    &-text {
      font: 600 14px/16px var(--font-family);
      padding-right: 8px;
      word-break: break-word;
      color: var(--own-box-top-txt-color);
    }

    &-online {
      font: 600 14px/16px var(--font-family);
      color: var(--own-box-top-txt-color);
    }

    .b-room-info {
      display: flex;
      align-items: center;

      gap: 16px;
    }

    &-speakers {
      position: relative;
      &-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--own-box-top-txt-color);

        gap: 8px;
        .cni {
          font-size: 18px;
        }
        &-text {
          font-size: 13px;
          font-weight: 500;
        }
        &.is-activated {
          color: var(--brand300);
        }
      }
      .b-speakers {
        &-box {
          position: absolute;
          top: 32px;
          display: grid;
          visibility: hidden;
          height: 0;
          padding: 8px 12px;
          opacity: 0;
          border-radius: 8px;
          background: #ffffff;
          background-color: var(--bg-popup);
          box-shadow: var(--box-shadow);

          gap: 8px;
          &.is-activated {
            visibility: visible;
            min-width: 240px;
            height: auto;
            white-space: nowrap;
            opacity: 1;
          }
          .c-button {
            justify-content: center;
          }
        }
        &-list {
          display: block;
          overflow-y: auto;
          max-height: 225px;
          margin: 0;
          padding: 0;
          list-style: none;
          &-item {
            padding: 6px 0;
            color: var(--own-box-top-txt-color);
            border-bottom: 1px solid var(--bg-delimiter);
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &-me {
    position: relative;
    z-index: 98;
    display: inline-flex;
    align-items: center;
    opacity: 0;
    border: 1px solid var(--video-bg);
    border-radius: 8px;
    background-color: var(--video-bg);
    box-shadow: var(--box-shadow);

    justify-content: center;
    @media (min-width: 576px) {
      position: fixed;
      top: auto;
      right: auto;
      bottom: 16px;
      left: 16px;
    }
    &.is-activated {
      border-color: var(--brand200);
    }
    &.is-visible {
      opacity: 1;
    }
    &-video {
      min-width: 176px;
      max-width: 100%;
      min-height: 176px;
      border-radius: 8px;
      background-color: var(--video-bg);
      @media (max-width: 575px) {
        max-height: 20vh;
      }
      @media (min-width: 576px) {
        width: 176px;
        height: 176px;
      }
      @media (min-width: 768px) {
        width: 240px;
        height: 240px;
      }
    }

    &-text {
      font: 600 12px/16px var(--font-family);
      position: absolute;
      bottom: 8px;
      left: 8px;
      padding: 4px 8px;
      cursor: default;
      color: var(--white);
      border-radius: 8px;
      background-color: var(--brand400);
      box-shadow: var(--box-shadow);
    }
    &-actions {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      align-items: center;

      justify-content: center;
      gap: 8px;
    }
    &-btn {
      font-size: 18px;
      display: flex;
      align-items: center;
      width: 32px;
      height: 32px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 32px;

      justify-content: center;
    }
    &-mute-audio,
    &-mute-video {
      color: var(--white);
      background-color: var(--brand500);
      &.is-muted {
        color: var(--leave-btn-color);
        background-color: var(--leave-btn-bg);
      }
    }
    &-share {
      display: flex;
      color: var(--leave-btn-color);
      background-color: var(--leave-btn-bg);
      &.is-activated {
        color: var(--white);
        background-color: var(--brand500);
      }
      .cni {
        font-size: 18px;
      }
      .is-device-mobile & {
        display: none;
      }
    }
    &-leave {
      color: var(--leave-btn-color-hover);
      background-color: var(--leave-btn-bg-hover);
      .cni {
        font-size: 10px;
      }
    }
  }

  &-participant {
    overflow: hidden;
    flex: 0 0 auto;
    width: 100%;
    min-width: 160px;
    max-height: 50vh;
    padding: 16px;

    @media (min-width: 576px) {
      width: 50%;
    }

    @media (min-width: 992px) {
      max-width: 480px;
    }
    .fullscreenicon {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    &-list {
      position: relative;
      display: flex;
      align-items: center;

      align-content: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      @media (max-width: 575px) {
        overflow-y: auto;
      }
      @media (min-width: 576px) {
        min-height: 100%;
      }
    }

    &-content {
      position: relative;
      overflow: hidden;
      border: 1px solid var(--video-bg);
      border-radius: 8px;
      background-color: var(--video-bg);
      box-shadow: var(--box-shadow);

      .is-activated & {
        border-color: var(--brand200);
      }
    }

    &-wrapper {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;

      justify-content: center;
    }

    &-video {
      display: flex;
      width: 100%;
      height: auto;
      @media (max-width: 575px) {
        max-height: 568px;
      }
      &.is-vertical {
        width: auto;
        max-height: 100%;
      }
    }

    &-audio {
      display: none;
    }

    &-name {
      font: 600 16px/24px var(--font-family);
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;
      padding: 8px;
      color: var(--participant-name-txt-color);
      border-radius: 4px;
      background-color: var(--participant-name-bg);
    }
  }
}

.b-room-occupants {
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  &-blur {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--bg-blur);

    backdrop-filter: blur(1px);
  }
}

.b-speaker,
.b-speaker-muted {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  color: var(--brand500);

  justify-content: center;

  .cni {
    font-size: 24px;
  }
}

.switcher-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.b-modal-your-turn {
  &-overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--bg-blur-invert);
  }
  &-wrapper {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;

    justify-content: center;
  }
  &-window {
    display: grid;
    padding: 40px;
    text-align: center;
    color: var(--own-box-top-txt-color);
    border-radius: 16px;
    background-color: var(--bg-popup);

    gap: 32px;
  }
  &-header {
    font-size: 24px;
  }
  &-descr {
    font-size: 18px;
  }
  &-small {
    font-size: 14px;
    font-style: italic;
  }
}
video::-webkit-media-controls-start-playback-glyph {
  will-change: opacity;
}

@media (max-width: 575px) {
  .b-own-box-participant {
    padding: 16px 0;
  }
  .b-own-box-participant-video {
    max-height: 192px;
  }
  .b-own-box-participant-video.is-vertical {
    max-height: 192px;
  }
}

@media (min-width: 576px) {

  .b-own-box-participant .b-own-box-participant-content {
    padding-bottom: 100%;
  }

  .b-own-box-participant .b-own-box-participant-wrapper {
    position: absolute;
  }

  .b-own-box-participant .b-own-box-participant-video {
    width: auto;
    height: 100%;
  }
}


</style>