<template>
  <div class="watcher-connect-popup p-1 bg-white dark-bg-gray-700 dark-text-white">
    <div>
      <p>Вы - <b>наблюдатель</b> <i class="bi bi-incognito"></i></p>
      <p>Участники не видят и не слышат вас. </p>
      <p>Хотите присоединиться к беседе? </p>
    </div>
    <div class="d-flex gap-1">
      <button class="btn btn-primary btn-sm"
        @click="addToQueue()">Да</button>
      <button class="btn btn-light btn-sm"
        @click="$emit('hide-watcher-connect-popup')">Нет</button>
    </div>
    <!-- <div class="align-items-center bg-white bottom-0 d-flex dark-bg-gray-700 end-0 justify-content-center position-absolute rounded-1 start-0 top-0"
      v-if="showLoader">
      <div class="c-loader"></div>
    </div> -->
  </div>
</template>

<script>
import { addUserToQueue, getUsersQueue } from "../helpers/api";
export default {
  name: "WatcherConnectRequestPopup",
  props: ["sessionid", "roomid"],
  emits: ["hide-watcher-connect-popup"],
  mounted() {

  },
  methods: {
    addToQueue() {
      addUserToQueue(this.sessionid, this.roomid).then((res) => {
        console.log(res);
        this.$emit("hide-watcher-connect-popup");
      }).catch((er) => {
        console.error(er);
        this.$emit("hide-watcher-connect-popup");
      });
    },
    getQueue() {
      getUsersQueue(this.sessionid).then((res) => {
        console.log(res);
        this.$emit("hide-watcher-connect-popup");
      }).catch((er) => {
        console.error(er);
        this.$emit("hide-watcher-connect-popup");
      });
    }
  }
};
</script>

<style lang="scss">
  .watcher-connect-popup {
    position: absolute;
    bottom: calc(100% + 8px);
    border-width: 1px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);

    border-type: solid;
    &:after {
      position: absolute;
      top: 100%;
      left: 14px;
      width: 0;
      height: 0;
      content: "";
      pointer-events: none;
      border: solid transparent;
      border-width: 6px;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #ffffff;

      .theme-dark & {
        border-top-color: #384250;
      }
    }
  }

</style>