<template>
  <div class="b-page b-page--home">
    <OwnBox v-if="homePageType == 'webmoney'"></OwnBox>
    <ConfermallVideoChat v-if="homePageType == 'confermall'"></ConfermallVideoChat>
  </div>
</template>

<script>
import OwnBox from "../components/OwnBox.vue";
import ConfermallVideoChat from "../components/ConfermallVideoChat.vue";

var homePageType = "webmoney";

if (window.location.hostname.indexOf("videochat.confermall") > -1 || 
    window.location.hostname.indexOf("front.test.domain") > -1 || 
    window.location.search.indexOf("design=dark") > -1) {
  homePageType = "confermall";
} 

export default {
  name: "HomePage",
  components: {
    OwnBox,
    ConfermallVideoChat
  },
  data() {
    return {
      homePageType: homePageType
    };
  }
};
</script>

<style lang="scss" scoped>
</style>
