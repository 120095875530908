<template>
  <div class="confermall-device-selection">
    <div class="confermall-device-selection-frame">
      <div
        class="confermall-device-selection-frame-text"
        v-show="!hasVideo">
        Камера выключена
      </div>
      <video
        class="confermall-device-selection-frame-video"
        id="previewvideobox"
        autoplay
        playsinline
        v-show="hasVideo"></video>
    </div>
    <div class="confermall-device-selection-form">
      <div class="confermall-device-selection-form-top">
        <div class="alert alert-info mb-3">Настройте камеру и микрофон</div>
        <div class="s-subheader text-gray-700 mb-2">Устройства</div>
        <div
          class="mb-3"
          v-if="audioDevices">
          <b-dropdown class="form-control-select w-100">
            <template #button-content>
              <div class="dropdown-toggle-button-text">
                <i class="fi fi-microphone"></i>
                <span v-if="!audioDeviceSelected"> Микрофон </span>
                <span v-if="audioDeviceSelected">
                  {{ audioDeviceSelected.label }}
                </span>
              </div>
              <i class="fi fi-chevron-bottom-sm"></i>
            </template>
            <b-dropdown-item-button
              v-for="item of audioDevices"
              v-bind:key="item['deviceId']"
              @click="onChange(item)">
              <div
                class="dropdown-menu-item-text"
                :title="audioDeviceSelected.label">
                <span>
                  {{ item.label }}
                </span>
              </div>
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
        <div v-if="videoDevices">
          <b-dropdown class="form-control-select w-100">
            <template #button-content>
              <div class="dropdown-toggle-button-text">
                <i class="fi fi-video"></i>
                <span v-if="!videoDeviceSelected"> Камера </span>
                <span v-if="videoDeviceSelected">
                  {{ videoDeviceSelected.label }}
                </span>
              </div>
              <i class="fi fi-chevron-bottom-sm"></i>
            </template>
            <b-dropdown-item-button
              v-for="item of videoDevices"
              v-bind:key="item['deviceId']"
              @click="onChange(item)">
              <div
                class="dropdown-menu-item-text"
                :title="videoDeviceSelected.label">
                <span>
                  {{ item.label }}
                </span>
              </div>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="onChange(null)">
              <div
                class="dropdown-menu-item-text"
                title="Без камеры">
                <span>
                  Без камеры
                </span>
              </div>
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
      </div>
      <hr />
      <div class="confermall-device-selection-form-bottom">
        <button
          class="btn btn-lg btn-brand btn-brand-gradient w-100 justify-content-center"
          @click="joinToRoom()">
          <i class="fi fi-video"></i>
          <span v-if="!supportcall"> Присоединиться к звонку </span>
          <span v-if="supportcall"> Позвонить </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

const SEARCH_PARAMS = new URL(window.location.href).searchParams;

var supcal = "false";

if (SEARCH_PARAMS.get("supportcall")) {
  supcal = SEARCH_PARAMS.get("supportcall").toString();
}

export default {
  name: "ConfermallDeviceSelection",
  props: ["devices", "videoStream"],
  emits: ["restart-capture", "join-to-room"],
  data() {
    return {
      audioDevices: [],
      videoDevices: [],
      hasVideo: null,
      audioDeviceSelected: null,
      videoDeviceSelected: null,
      supportcall: false,
    };
  },
  mounted() {
    if (this.devices) {
      this.initDevices(this.devices);
    }

    if (supcal.toString() == "true" ) {
      this.supportcall = true;
    }

    if (!navigator.getUserMedia) {
      this.errorCallback();
    } else {
      navigator.getUserMedia({ video: true }, this.successCallback, this.errorCallback);
    }
  },
  methods: {
    errorCallback(e) {
      this.hasVideo = false;

      console.error(e);
    },
    successCallback(stream) {
      this.hasVideo = true;

      let previewvideobox = document.querySelector("#previewvideobox");
      previewvideobox.srcObject = stream;
    },
    initDevices(devices) {
      this.audioDevices = devices.filter(function (obj) {
        return obj.kind == "audioinput";
      });
      this.audioDeviceSelected = this.audioDevices[0];

      this.videoDevices = devices.filter(function (obj) {
        return obj.kind == "videoinput";
      });
      this.videoDeviceSelected = this.videoDevices[0];
    },
    onChange(item) {
      if (item) {
        if (item.kind == "audioinput") {
          this.audioDeviceSelected = item;
        }

        if (item.kind == "videoinput") {
          this.videoDeviceSelected = item;
          this.hasVideo = true;
        }
      } else {
        item = {};
        item.label = "Без камеры";
        item.novideo = true;
        item.kind = "videoinput";
        this.hasVideo = false;

        this.videoDeviceSelected = item;
      }
    },
    joinToRoom() {
      var data = {
        audio: this.audioDeviceSelected,
        video: this.videoDeviceSelected,
      };

      if (this.videoDeviceSelected.novideo) {
        data = {
          audio: this.audioDeviceSelected
        };
      } 

      this.$emit("join-to-room", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/confermall/confermall-main.scss";

.confermall-device-selection {
  display: flex;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: 0 16px;

  gap: 40px;

  @media (min-width: 768px) {
    max-width: 100%;
    max-height: 100%;
  }

  @media (min-width: 1280px) {
    max-width: 1148px;
  }
  &-frame {
    overflow: hidden;
    flex: 1;
    border-radius: 24px;
    background-color: $gray-900;

    @media (max-width: 767px) {
      display: none;
    }
  }
  &-frame-text {
    display: flex;
    align-items: center;
    height: 100%;
    color: $white;

    justify-content: center;
  }
  &-frame-video {
    width: 100%;
    height: 100%;
  }
  &-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;
    border-radius: 24px;
    background-color: $white;
    @media (min-width: 768px) {
      width: 388px;
    }
    hr {
      margin: 24px 0;
      opacity: 1;
      border-color: $gray-100;
    }
  }
  &-form-top {
    flex: 1;
    padding: 0 24px;
  }
  &-form-bottom {
    padding: 0 24px;
  }
}

</style>
