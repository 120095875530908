require("./assets/styles/main.scss");

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vue from "vue";
import VueCookies from "vue-cookies";
import device from "vue-device-detector";
import VueRouter from "vue-router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from "./App.vue";
import HomePage from "./pages/HomePage.vue";
import RecordPage from "./pages/RecordPage.vue";
import AuthSuccessPage from "./pages/auth/AuthSuccessPage.vue";
const toastOptions = {
  // You can set your default options here
};

Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(Toast, toastOptions);
Vue.use(device);
Vue.use(BootstrapVue);

const routes = [
  { path: "/", component: HomePage },
  { path: "/auth-success", component: AuthSuccessPage },
  { path: "/record", component: RecordPage}
];

const router = new VueRouter({
  mode: "history",
  routes
});



// socket.io.on("ping", () => {
//   // alert(1)
// });

// socket.io.on("error", (error) => {
//   // alert(2)
// })


// manager.open((err) => {
//   if (err) {
//     // an error has occurred
//   } else {
//     // the connection was successfully established
//   }
// });

new Vue({
  el: "#app",
  router: router,
  render: h => h(App),
});