<template>
 <div @click="launchFullScreen()">
  <span class="fullicon" title="На весь экран"><i class="cni cni-focus"></i></span>  
 </div>
</template>

<script>
export default {
  name: "FullScreen",
  props: [ 
    "userid"
  ],
  emits: [
  ],
  methods: {
    launchFullScreen() {
      let videoelem = document.querySelector("#videobox-"+this.userid);

      if (videoelem.requestFullscreen) {
        videoelem.requestFullscreen();
      } else if (videoelem.webkitRequestFullscreen) {
        videoelem.webkitRequestFullscreen();
      } else if (videoelem.msRequestFullscreen) {
        videoelem.msRequestFullscreen();
      }
    }
  },
  mounted() {
  }

};
</script>
<style lang="scss" scoped>
 .fullicon {
   position: absolute;
   z-index: 98;
   top: 8px;
   right: 8px;
   display: flex;
   align-items: center;
   width: 40px;
   height: 40px;
   cursor: pointer;
   color: var(--full-screen-button);
   border-radius: 4px;
   background-color: red;
   background-color: var(--participant-name-bg);

   justify-content: center;
 }

</style>