<template>
  <div :class="{'eventschat-activated': showEventsChatSidebar}">
    <div class="btn-eventschat" @click="toggleEventsChat()"></div>
    <div class="ewm-widget-wrapper">
      <div id="wm-events-holder"
        class="ewm-widget-comments"
        data-lang="ru-RU"
        data-collapse="true"
        data-online-users="true"
        data-design="1"
        v-bind="{ 'data-uid': eventsid }"
        data-width="100%"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "EventsChat",
  props: [ 
    "eventsid"
  ],
  data() {
    return {
      showEventsChatSidebar: false
    }
  },
  mounted() {
 
    this.initEventsChat();
  },
  methods: {
    initEventsChat() {
      (function (w, d, id) {w.ewmAsyncWidgets = function () { EWM.Widgets.init(); };if (!d.getElementById(id)) {var s = d.createElement("script"); s.id = id; s.async = true;s.src = "https://events.web.money/js/ewm-api.js?13";(d.getElementsByTagName("head")[0] || d.documentElement).appendChild(s);}})(window, document, "ewm-js-api");
    },
    toggleEventsChat() {

      if (this.showEventsChatSidebar) {

        this.showEventsChatSidebar = false;
      } else {

        this.showEventsChatSidebar = true;
      }
    }
  }
}
</script>

<style lang="scss">
.btn-eventschat {
  position: fixed;
  z-index: 99;
  right: 16px;
  bottom: 16px;
  display: none;
  width: 64px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 64px;
  background-color: #006cb4;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiIGNsYXNzPSIiPjxnPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yNTUsMTZDMTE0LjM5LDE2LDAsMTE2LjkyOCwwLDI0MWMwLDUyLjQxMiwyMC43NDIsMTAzLjE5OCw1OC40OTEsMTQzLjQ1MmM3LjQ3MSwzMC44MDYtMS41OTcsNjMuNDQyLTI0LjA5Nyw4NS45NDIKCQkJQzI0Ljk4Niw0NzkuODAzLDMxLjYzOSw0OTYsNDUsNDk2YzQyLjc0NCwwLDgzLjk1LTE2Ljc0MywxMTQuNTY1LTQ2LjI3NEMxODkuODU4LDQ2MC41MzYsMjIxLjkwOSw0NjYsMjU1LDQ2NgoJCQljMTQwLjYxLDAsMjU3LTEwMC45MjgsMjU3LTIyNVMzOTUuNjEsMTYsMjU1LDE2eiBNMTM3LDI4NmMtMjQuODE0LDAtNDUtMjAuMTg2LTQ1LTQ1YzAtMjQuODE0LDIwLjE4Ni00NSw0NS00NQoJCQljMjQuODE0LDAsNDUsMjAuMTg2LDQ1LDQ1QzE4MiwyNjUuODE0LDE2MS44MTQsMjg2LDEzNywyODZ6IE0yNTcsMjg2Yy0yNC44MTQsMC00NS0yMC4xODYtNDUtNDVjMC0yNC44MTQsMjAuMTg2LTQ1LDQ1LTQ1CgkJCWMyNC44MTQsMCw0NSwyMC4xODYsNDUsNDVDMzAyLDI2NS44MTQsMjgxLjgxNCwyODYsMjU3LDI4NnogTTM3NywyODZjLTI0LjgxNCwwLTQ1LTIwLjE4Ni00NS00NWMwLTI0LjgxNCwyMC4xODYtNDUsNDUtNDUKCQkJYzI0LjgxNCwwLDQ1LDIwLjE4Niw0NSw0NUM0MjIsMjY1LjgxNCw0MDEuODE0LDI4NiwzNzcsMjg2eiIgZmlsbD0iI2ZmZmZmZiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9IiI+PC9wYXRoPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjwvZz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 26px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  @media (min-width: 992px) {
    display: block;
  }
  .eventschat-activated & {
    @media (min-width: 992px) {
      right: 376px;
    }
  }
}

.ewm-widget-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  right: -400px;
  bottom: 0;
  width: 360px;
  height: 100%;
  transition: right 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0 , 0.1);
  .eventschat-activated & {
    @media (min-width: 992px) {
      right: 0;
    }
  }
}

</style>