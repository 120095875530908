<template>
  <div class="b-user-block">
    <div class="b-user-block-wrapper" v-if="user">
      <div class="b-user-block-avatar" v-if="user.icon">
        <img class="b-user-block-avatar-image" :src="user.icon.normal"/>
      </div>
      <div class="b-user-block-name">
        {{user.nickname}}
      </div>
    </div>
  </div>
</template>

<script>
import { getAuthInfo } from "../helpers/api";
export default {
  name: "UserBlock",
  props: [ 
    "id",
    "encryptedkey",
    "authbtn",
    "userme"
  ],
  emits: [
    "user-rendered"
  ],
  data() {
    return {
      user: null
    };
  },
  mounted() {
    if (!this.userme) {
      getAuthInfo(this.encryptedkey).then((res) => {
        this.user = res;
        var data = {
          id: this.id,
          user: this.user
        };

        if (this.authbtn == "true") {
          this.$emit("user-rendered", data);
        }
      }).catch((er) => {
        console.error(er);
      });
    } else {
      this.user = this.userme;
    }
  }
};
</script>

<style lang="scss">
  .b-user-block {
    &-wrapper {
      display: flex;
      align-items: center;
      cursor: default;
    }
    &-avatar {
      margin-right: 8px;
      &-image {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        .b-no-video-avatar & {
          width: 128px;
          height: 128px;
          box-shadow: var(--box-shadow);
        }
      }
    }
    &-name {
      font: 600 14px/16px var(--font-family);
      margin-right: 8px;
      .b-no-video-avatar & {
        display: none;
      }
    }
  }

</style>