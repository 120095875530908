import { render, staticRenderFns } from "./WaitingQueuePopup.vue?vue&type=template&id=52165308"
import script from "./WaitingQueuePopup.vue?vue&type=script&lang=js"
export * from "./WaitingQueuePopup.vue?vue&type=script&lang=js"
import style0 from "./WaitingQueuePopup.vue?vue&type=style&index=0&id=52165308&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports