/* global __BASE_URL__ */
/* global __HOSTNAME_API__ */
/* global __QUERY_KEYS__*/
const BASE_URL = __BASE_URL__;
const HOSTNAME_API = JSON.parse(__HOSTNAME_API__);
const QUERY_KEYS = __QUERY_KEYS__;
import axios from "axios";
import VueCookies from "vue-cookies";

var API_URL = BASE_URL;

if (HOSTNAME_API[window.location.hostname]) {
  API_URL = HOSTNAME_API[window.location.hostname];
}

export function get(url, params) {
  var headers = getHeaders(QUERY_KEYS);

  return new Promise(function (resolve, reject) {
    axios
      .get(url, {
        headers: headers,
        params: params
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response));
  });
}

export function post(url, data) {
  var headers = getHeaders(QUERY_KEYS);

  return new Promise(function (resolve, reject) {
    axios
      .post(url, data, {
        headers: headers
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response));
  });
}

export function put(url, data) {
  var headers = getHeaders(QUERY_KEYS);

  return new Promise(function (resolve, reject) {
    axios
      .put(url, data, {
        headers: headers
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response));
  });
}

export function remove(url, data) {
  var headers = getHeaders(QUERY_KEYS);

  return new Promise(function (resolve, reject) {
    axios
      .delete(url, {
        headers: headers,
        data: data
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response));
  });
}

function getAuthValidateToken() {
  return get(`${API_URL}/auth/validate_token`, {});
}

function createTokenForMediaServer() {
  return post(`${API_URL}/authtokens`, {});
}

function getAuthInfo(token) {
  return get(`${API_URL}/authtokens/getinfo`, {tokenvalue: token});
}

function getRoomOccupants(roomid) {
  return get(`${API_URL}/rooms/${roomid}`, {});
}

function getHeaders(keys) {
  var headers = {};

  if (allKeysExistsInCookies(keys)) {
    for (let i = 0; i < keys.length; i++) {
      headers[keys[i]] = VueCookies.get([keys[i]]);
    }
  }

  return headers;
}

function saveLoginInfotoDB(data){
  return post(`${API_URL}/janussessions`, data);
}

function allKeysExistsInCookies(array) {
  for (let i = 0; i < array.length; i++) {
    if (!VueCookies.isKey(array[i])) {
      return false;
    }
  }

  return true;
}

function deleteFromQueue(sessionid, roomid) {
  return remove(`${API_URL}/queueitems/${sessionid}?roomid=${roomid}`, {});
}

function addUserToQueue(sessionid, roomid) {
  return post(`${API_URL}/queueitems?roomid=${roomid}&sessionid=${sessionid}`, {});
}

function getUsersQueue() {
  return get(`${API_URL}/queueitems`, {});
}

function getUsersQueuePosition(sessionid) {
  return get(`${API_URL}/queueitems/${sessionid}`, {});
}

function getYourTurn(sessionid) {
  return get(`${API_URL}/queueitems/new?sessionid=${sessionid}`, {});
}

function notifyRecordStartedOrStopped(id,action,recordid) {
  return post(`${API_URL}/records`, {sessionid: id, record_action: action, recordid: recordid});
}

function useRecord(recordid) {
  return put(`${API_URL}/records/${recordid}`, {});
}

function notifyJoined(roomid,userid){
  return post(`${API_URL}/roomjoins`, {room_id: roomid, janususerid: userid});
}

export {
  addUserToQueue, allKeysExistsInCookies, createTokenForMediaServer, deleteFromQueue, getAuthInfo, getAuthValidateToken, getRoomOccupants, getUsersQueue,
  getUsersQueuePosition,
  getYourTurn,
  notifyJoined,
  notifyRecordStartedOrStopped, saveLoginInfotoDB, useRecord
};
