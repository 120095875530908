<template>
  <div class="b-toast-user">
    <UserBlock :id="userid" :encryptedkey="userdisplay"></UserBlock>
    <span>{{text}}</span>
  </div>
</template>

<script>
import UserBlock from "./UserBlock.vue";
export default {
  name: "UserBlockToast",
  components: {
    UserBlock
  },
  props: [ 
    "userid",
    "userdisplay",
    "text"
  ],
};
</script>

<style lang="scss">
.b-toast-user {
  display: flex;
  align-items: center;
}

</style>