<template>
  <div class="b-app" id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss" scoped>
  .b-app {
    height: 100%;
  }
</style>
