<template>
  <div
    class="btn-me-share"
    :class="{ 'is-activated': shareStarted }">
    <button class="btn-action btn-share-screen"
      @click="startScreenShare2()"
      v-if="!shareStarted"
      title="Показать экран">
    </button>
    <button class="btn-action btn-stop-share-screen"
      @click="stopScreenShare()"
      v-if="shareStarted"
      title="Остановить показ">
    </button>
    <!-- <button @click="shotracks()" title="">

    </button> -->
  </div>
</template>
<script>
var confConn;

export default {
  name: "ConfermallScreenShare",
  props: ["conferenceConnection", "devices", "sharestream", "localtracks", "mylocaltracks"],
  emits: [],
  data() {
    return {
      user: null,
      opaqueId: "screenshare-" + parseInt(Math.random(10) * 10000),
      screenPlugin: null,
      shareStarted: false,
      sharetrack: null,
      curStream:null
    };
  },
  mounted() {},

  methods: {

    shotracks() {
      let trakcs = this.conferenceConnection.getRemoteTracks();
      console.log(trakcs);
    },  

    startScreenShare2() {
      confConn = this.conferenceConnection;
      let tracks = [];
      tracks.push({ type: "screen", capture: true, recv: false });
      this.conferenceConnection.createOffer({
        iceRestart: true,
        tracks: tracks,
        success: (jsep) => {
          console.log(jsep);
          this.conferenceConnection.send({ message: { request: "configure", video: true }, jsep: jsep });
          //this.conferenceConnection.send({ message: { request: "update"}, jsep: jsep });
          this.shareStarted = true;
        },
        error: (error) => {console.log("ERROR", error);}
      });
    },
    

    returnMyVideostr() {
      let elem = document.querySelector("#current-user-video");
      let tr = this.mylocaltracks[1];
      console.log("==============", tr);
      let mystr = new MediaStream([tr]);
      console.log("========== 00000  ", mystr);
      elem.srcObject = null;
      setTimeout(() => {
        elem.srcObject = mystr;
      }, 300);
    },

    stopScreenShare() {
      this.returnMyVideostr();

      let trck = this.conferenceConnection.getLocalTracks();
      // window.mytracks = trck;
      // this.sharestream.stop();
      let shareid = this.sharestream.id;
      this.shareStarted = false;

      trck.forEach(track => {
        if (track.id.toString() == shareid.toString()) {
          this.sharetrack = track;
        }
      });
      // console.log("ALL LOCALTRACKS +++++++++++++++++++++", this.conferenceConnection.getLocalTracks());
      // let keys = Object.keys(this.localtracks);
      // for(let l in keys){
      //   console.log("localTrackslocalTrackslocalTrackslocalTracks", this.localtracks[keys[l]]);
      // }
      // let trk = this.conferenceConnection.getLocalTracks()[1];
      //let stream = new MediaStream([trk]);

      // let elem = document.querySelector("#current-user-video");
      // elem.srcObject = stream;

      // console.log(elem);
      // console.log("===== ",this.curStream);
      console.log("this.sharetrack ", this.sharetrack);
      console.log("this.localtracks", this.localtracks);
      let stream = this.localtracks[shareid];

      if(stream){
        let loctracks = stream.getTracks();
        for(var i in loctracks) {
          let mst = loctracks[i];
          if(mst){
            mst.stop();
          }
        }
      }

      this.conferenceConnection.createOffer({
        iceRestart: true,
        tracks: [{ type: "video", mid: this.sharetrack.mid, remove: true }],
        
        success: function(jsep) {
          confConn.send({ message: { request: "configure", video: true}, jsep: jsep});
        },
        error: function(error) {
          console.log("Error sending: ", error);
        }
      });
      

      //this.conferenceConnection.send({ message: { request: "configure", video: false}});


      // let trk = this.sharestream.getVideoTracks()[0];
      // trk.stop();


      // tracks.push({ type: "video", capture: true, recv: true, simulcast: true });

      // this.conferenceConnection.createOffer({
      //   iceRestart: true,
      //   tracks: tracks,
      //   success: (jsep) => {
      //     this.conferenceConnection.send({ message: { request: "configure", video: true }, jsep: jsep });
      //     this.shareStarted = false;
      //   },
      //   error: (error) => {console.log("ERROR", error);}
      // });
    },
    
    stopScreenShare2() {
      this.unpublishOwnFeed();

      let localTracks = this.conferenceConnection.getLocalTracks();
      let scope = this;
      setTimeout(() => {
        let tracks = [];
        localTracks.forEach(track => {
          if (track.type == "audio") {
            let trackData = {};
            trackData.type = track.type;
            trackData.capture = track;
            trackData.recv = false;

            tracks.push(trackData);
          }
        });

        let videoDevice = scope.devices.find(device => {
          if (device.kind == "videoinput") {
            return device;
          }
        });

        tracks.push({
          type: "video",
          capture: { deviceId: { exact: videoDevice.deviceId } },
          recv: false,
        });

        scope.conferenceConnection.createOffer({
          iceRestart: true,
          tracks: tracks,
          success: (jsep) => {
            scope.conferenceConnection.send({ message: { request: "configure", video: true }, jsep: jsep });
            scope.shareStarted = false;
            this.$emit("stop-screen-share");
          },
          error: (error) => {
            console.log("ERROR", error);
          },
        });
      }, 1000);
    },

    unpublishOwnFeed() {
      let unpublish = { request: "unpublish" };
      this.conferenceConnection.send({ message: unpublish });
    },

  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/confermall/confermall-main.scss";

</style>