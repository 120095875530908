<template>
  <div class="b-page b-page--auth">
    Вы успешно авторизованы
  </div>
</template>

<script>

export default {
  name: "AuthSuccessPage",
  mounted() {
    var queryKeys = ["access-token", "client", "expiry", "uid", "token-type"];

    if (this.allKeysExistsInCookies(queryKeys)) {
      this.closeWindow();
    }
  },
  methods: {
    allKeysExistsInCookies(array) {
      for (let i = 0; i < array.length; i++) {
        if (!this.$cookies.isKey(array[i])) {
          return false;
        }
      }

      return true;
    },
    closeWindow() {
      window.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.b-page--auth {
  display: flex;
  align-items: center;

  justify-content: center;
}

</style>
