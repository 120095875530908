<template>
  <div class="b-users-visibility-switcher">
    <label class="b-users-visibility-switcher-checkbox-label" :class="{'is-activated': isUsersVisible}">
      <input type="checkbox"
        class="b-users-visibility-switcher-checkbox"
        id="js-theme-switcher"
        @change="toggleUsersVisibility(isUsersVisible)"
        v-model="isUsersVisible">
      <span class="b-users-visibility-switcher-ball"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "UsersVisibilitySwitcher",
  emits: [
    "user-visibility-changed"
  ],
  data() {
    return {
      isUsersVisible: true
    };
  },
  mounted() {
    if (this.$cookies.isKey("usersVisibile")) {
      if (this.$cookies.get("usersVisibile") == "false") {
        this.isUsersVisible = false;
        this.toggleUsersVisibility(false);
      }
    }
  },
  methods: {
    toggleUsersVisibility(value) {
      if (value) {
        this.$cookies.set("usersVisibile", "true");
      } else {
        this.$cookies.set("usersVisibile", "false");
      }

      this.$emit("user-visibility-changed", value);
    }
  }
};
</script>

<style lang="scss" scoped>
  .b-users-visibility-switcher {
    &-checkbox {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }
    &-checkbox-label {
      display: inline-flex;
      width: 28px;
      margin-left: 5px;
      cursor: pointer;
      transition: margin-right 0.3s;
      border-radius: 10px;
      background: #de0909;
      &.is-activated {
        background: #67c28f;
      }
    }
    .cni-moon {
      font-size: 18px;
      margin-top: -6px;
      margin-left: 2px;
      color: var(--gray500);
    }
    .cni-sun {
      color: var(--gray500);
    }
    &-ball {
      display: block;
      width: 12px;
      height: 12px;
      margin: 2px;
      transition: all 0.3s;
      border-radius: 50%;
      background: #ffffff;
    }
    .b-users-visibility-switcher-checkbox:checked ~ .b-users-visibility-switcher-ball {
      margin-left: 14px;
    }
  }

</style>