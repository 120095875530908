<template>
  <div class="waiting-popup p-1 bg-white dark-bg-gray-700 dark-text-white">
    <div>
      Ваш номер в очереди: {{numberInQueue}}
    </div>
  </div>
</template>

<script>
import { getUsersQueuePosition } from "../helpers/api";
export default {
  name: "WaitingQueuePopup",
  props: ["sessionid"],
  emits: ["hide-waiting-popup"],
  data() {
    return {
      numberInQueue: 0
    };
  },
  mounted() {
    this.getUsersQueuePos();

    setInterval(() => {
      this.getUsersQueuePos();
    }, 5000);
  },
  methods: {
    getUsersQueuePos() {
      getUsersQueuePosition(this.sessionid).then((res) => {
        this.numberInQueue = res.position;
      }).catch((er) => {
        console.error(er);
      });
    }
  }
};
</script>

<style lang="scss">
  .waiting-popup {
    position: absolute;
    bottom: calc(100% + 8px);
    border-width: 1px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);

    border-type: solid;
    &:after {
      position: absolute;
      top: 100%;
      left: 14px;
      width: 0;
      height: 0;
      content: "";
      pointer-events: none;
      border: solid transparent;
      border-width: 6px;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #ffffff;

      .theme-dark & {
        border-top-color: #384250;
      }
    }
  }

</style>