<template>

  <div class="confermall-chat-page" :class="{'height-auto': participants.length > 1}">
    <!-- <div id="test" style="z-index:999;argin-top:30px;margin-bottom:30px;position:absolute;top:0px;color:white; background-color: black;margin-bottom:20px;margin-top:20px;">
      <div style="margin-top:20px;margin-bottom:20px;">{{myid}}</div>
      <div>
        {{ participants }}
      </div>
      
    </div> -->

    <ConfermallDeviceSelection
      v-if="!isLoading && currentUser.hasAudio && currentUser.hasVideo && !currentUser.joined" 
      :devices="devices"
      @join-to-room="onJoinToRoom"></ConfermallDeviceSelection>
    <div
      class="py-2 px-3 bg-gray-700 text-white rounded-pill"
      v-if="!isLoading && !currentUser.hasAudio && !currentUser.hasVideo && currentUser.auth && !currentUser.joined">
      <span class="s-subheader"> Не удалось получить доступ к микрофону, пожалуйста, подключите и настройте микрофон на Вшаем устройстве. </span>
    </div>
    <div
      class="py-2 px-3 bg-gray-700 text-white rounded-pill"
      v-if="audioAndVideoDeniedBySystem">
      <span class="s-subheader"> 
        Не удалось получить доступ к микрофону, пожалуйста, настройте микрофон пожалуйста, подключите и настройте микрофон на Вшаем устройстве. 
      </span>
    </div>
    <div v-if="!isLoading && !currentUser.auth">
      <div class="py-2 px-3 bg-gray-700 text-white rounded-pill">
        <span class="s-subheader"> Не удалось вас узнать. </span>
      </div>
    </div>
    <div v-if="!isLoading && currentUser.hasAudio && !currentUser.hasVideo && !currentUser.joined">
      <button
        class="btn btn-lg btn-brand btn-brand-gradient"
        @click="joinToRoom()">
        <i class="fi fi-video"></i>
        <span v-if="!supportcall"> Присоединиться к звонку </span>
        <span v-if="supportcall"> Позвонить </span>
      </button>
    </div>
    <div
      class="w-100 h-100"
      v-show="currentUser.joined && !audioAndVideoDeniedBySystem">
      <div class="d-flex justify-content-center flex-column w-100 h-100">
        <div class="d-flex justify-content-center flex-column flex-fill w-100 h-100">
          <div
            class="b-no-participants d-flex flex-column justify-content-center align-items-center"
            v-if="participants.length == 0">
            <div class="user-window-text">
              <span class="s-big-text-bold" v-if="!supportcall"> Собеседник еще не подключился </span>
              <span class="s-big-text-bold" v-if="supportcall">Ожидание ответа оператора </span>
            </div>
            <div class="user-window">
              <div class="user-window-box">
                <div class="user-window-loader"></div>
                <div class="user-avatar-loader"></div>
              </div>
            </div>
          </div>

          <div class="participant-window-wrapper" v-if="participants.length > 0" 
            :class="'participant-window-wrapper-count-' + participants.length">
            <div class="participant-window" v-for="participant in participants" v-bind:key="participant['id']">
              <div class="participant-window-with-video w-100">
                <!-- <div id="camstat">
                  {{ participant }}
                </div> -->
                <video @dblclick="LaunchFullScreen($event)" class="w-100 h-100" autoplay playsinline muted v-bind:id="'videobox-' + participant['id']"></video>
              </div>

              <div class="participant-window-overlay-no-video" v-if="participant.hasVideo == false">
                <div class="user-window">
                  <div class="user-avatar-plug">
                    <i class="fi fi-user-filled"></i>
                  </div>
                </div>
                <div class="participant-window-overlay-no-video-text text-white">
                  <span v-if="!supportcall"> Собеседник отключил камеру </span>
                  <span v-if="supportcall"> Оператор отключил камеру </span>
                </div>
              </div>



              <!-- <div class="participant-window-no-video" v-if="!participant.hasVideo || participant.videoDisabled">
                <div style="margin-bottom:16px;">
                  <img style="width:24px;" src="/images/connected.png"/>
                  <div style="color: white;margin-left:5px;font-weight: 600;display:inline-flex;">
                    Соединение установлено
                  </div>
                </div>
                <div class="user-window">
                  <div class="user-avatar-plug">
                    <i class="fi fi-user-filled"></i>
                  </div>
                </div>

                <div class="py-2 px-3 bg-gray-700 text-white rounded-pill text-center">
                  <span class="s-big-text-bold" v-if="!supportcall"> Собеседник отключил камеру </span>
                  <span class="s-big-text-bold" v-if="supportcall"> Оператор отключил камеру </span>
                </div>
              </div> -->

              <audio class="participant-window-audio" autoplay playsinline v-bind:id="'audiobox-' + participant['id']"></audio>
            </div>
          </div>
          
          <div class="current-user-videobox">
            <video class="current-user-videobox-video" autoplay playsinline id="current-user-video" v-show="currentUser.hasVideo && !currentUser.videoDisabled"></video>

            <div class="current-user-videobox-no-video" v-show="!currentUser.hasVideo || currentUser.videoDisabled">
              <div class="user-avatar-plug">
                <i class="fi fi-user-filled"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="current-user-actions">
          <button class="btn btn-icon btn-red-25" title="Завершить звонок" @click="leaveRoom()">
            <i class="fi fi-call-end"></i>
          </button>
          <button class="btn btn-icon" :class="currentUser.hasAudio && !currentUser.audioDisabled ? 'btn-green-25' : 'btn-brand-25'" @click="muteUnmuteAudio()">
            <i class="fi fi-microphone" v-if="currentUser.hasAudio && !currentUser.audioDisabled"></i>
            <i class="fi fi-no-microphone" v-if="!currentUser.hasAudio || currentUser.audioDisabled"></i>
          </button>
          <button
            class="btn btn-icon"
            :class="currentUser.hasVideo && !currentUser.videoDisabled ? 'btn-green-25' : 'btn-brand-25'"
            @click="muteUnmuteVideo()">
            <i
              class="fi fi-video"
              v-if="currentUser.hasVideo && !currentUser.videoDisabled"></i>
            <i
              class="fi fi-no-video"
              v-if="!currentUser.hasVideo || currentUser.videoDisabled"></i>
          </button>
          <ConfermallScreenShare v-if="conferenceConnection" ref="shareButtonComponentRef" :localtracks="localtracks" :conferenceConnection="conferenceConnection" :sharestream="sharestream" :devices="devices" :mylocaltracks="mylocaltracks"></ConfermallScreenShare>
        </div>
      </div>
    </div>
    <ConfermallLoader v-if="isLoading"></ConfermallLoader>
  </div>
</template>

<script>
import { Janus } from "janus-gateway";
import ConfermallDeviceSelection from "../components/ConfermallDeviceSelection.vue";
import ConfermallLoader from "../components/ConfermallLoader.vue";
import ConfermallScreenShare from "../components/ConfermallScreenShare.vue";
import { createTokenForMediaServer, getAuthValidateToken, getRoomOccupants, notifyJoined } from "../helpers/api";

const JANUS_URL = "wss://jsc.webmoney.com/janus";
var opaqueId = "videoroom-" + Janus.randomString(12);
var myroom = 124566;
var supcal = "false";
var feedStreams = {};
var feeds = {};
var subscriptions = {};
var slots = {};
var mids = {};
var subStreams = {};
//var remoteFeed = null;
var remoteTracks = {};
var streamsIAlreadySubscribedTo = [];
var aleradyJoinedAtFirstTime = false;
var use_msid = false;
var mypvtid = null;

window.allstreams = [];
window.alltracks = [];

//window.mylocaltracks = [];


const SEARCH_PARAMS = new URL(window.location.href).searchParams;

if (SEARCH_PARAMS.get("roomid")) {
  myroom = parseInt(SEARCH_PARAMS.get("roomid"));
}

if (SEARCH_PARAMS.get("supportcall")) {
  supcal = SEARCH_PARAMS.get("supportcall").toString();
}

export default {
  name: "ConfermallVideoChat",
  components: {
    ConfermallLoader,
    ConfermallDeviceSelection,
    ConfermallScreenShare
  },
  data() {
    return {
      showConnectVideoChatButton: false,
      devices: [],
      supportcall: false,
      myid: null,
      mutedVideo: {},
      currentUser: {
        id: null,
        auth: false,
        hasVideo: false,
        hasAudio: false,
        videoDisabled: false,
        audioDisabled: false,
        encryptedKey: null,
        objectFromResponse: null,
        joined: false,
      },
      isLoading: false,
      localtracks: {},
      roomid: myroom,
      // participants: [],
      participants: [],
      remoteFeed: null,
      alreadySubscribedAtFirstTime: false,
      streamsIAlreadySubscribedTo: [],
      selectedDevices: null,
      audioAndVideoDeniedBySystem: false,
      conferenceConnection: null,
      userStreams: {},
      alreadyAttachedStreams: [],
      sharestream: null,
      mylocaltracks: []
    };
  },
  mounted() {
    this.isLoading = true;
    this.initJanus();
    document.body.classList.add("theme-confermall-chat");
  },
  methods: {

    initJanus() {
      if (supcal.toString() == "true") {
        this.supportcall = true;
      }
      Janus.init({
        debug: true,
        dependencies: Janus.useDefaultDependencies(),
        callback: () => {
          getAuthValidateToken()
            .then(() => {
              this.currentUser.auth = true;
              this.getMediaServerToken();
            })
            .catch((error) => {
              this.logError(error);
              this.isLoading = false;
            });
        },
      });
    },
    LaunchFullScreen(event){
      console.log(event.target);

      let elem = event.target;
      
      elem.requestFullscreen();
    },
    getMediaServerToken() {
      createTokenForMediaServer()
        .then((resp) => {
          var connectOptions = {
            server: JANUS_URL,
            token: resp.tokenvalue,
            turnpw: resp.password,
          };
          this.connectJanus(connectOptions);

          this.currentUser.encryptedKey = resp.encryptedkey;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    connectJanus(options) {
      this.janus = new Janus({
        server: options.server,
        iceServers: [
          {
            //url: "turn:51.83.45.120:443?transport=tcp",
            url: "turn:51.83.45.120:53?transport=udp",
            username: options.token,
            credential: options.turnpw,
          },
          {
            url: "turn:51.83.45.120:443?transport=tcp",
            username: options.token,
            credential: options.turnpw,
          }
        ],
        token: options.token,
        success: () => {
          this.attachJanus();
        },
        error: (error) => {
          this.logError(error);
        },
        destroyed: () => {
          window.location.reload();
        },
      });
    },
    attachJanus() {
      this.janus.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: opaqueId,
        success: (plugin) => {
          this.conferenceConnection = plugin;
          this.checkUserMedia();
        },
        error: (error) => {
          this.logError(error);
        },
        onmessage: (msg, jesp) => {
          let event = msg["videoroom"];

          if (event != undefined && event != null) {
            this.eventProcessor(msg);
          }

          if (jesp) {
            this.jsepProcessor(jesp);
          }
        },
        onlocaltrack: (track, on, mid) => {
          console.log("onlocaltrackonlocaltrackonlocaltrack GOR MID : ", track, mid);
          let trackId = track.id.replace(/[{}]/g, "");
          console.log("GOT LOCAL TRACK;", track, on, trackId);
          this.mylocaltracks.push(track);
          let mylocalstream = new MediaStream([track]);
          this.localtracks[trackId] = mylocalstream;
          this.sharestream = track;
          console.log("STREAM AND TRACK : ", track, mylocalstream);
          if(!on){
            if (this.localtracks[trackId]) {
              let vidtrack = this.localtracks[trackId].getVideoTracks()[0];
              if(vidtrack){
                console.log("VIDTRACK; ", vidtrack);
                vidtrack.stop();
              }
            }
          }
          if (track.kind === "video") {
            let ownbox = document.querySelector("#current-user-video");
            Janus.attachMediaStream(ownbox, mylocalstream);
            this.currentUser.hasVideo = true;
            // this.currentUser.videoDisabled = false;
          }
          if (track.kind === "audio") {
            this.currentUser.hasAudio = true;
            // this.currentUser.audioDisabled = false;
          }
        },
        oncleanup: () => {
          // if (this.becomeSpeakerProcess) {
          //   this.attachJanus();
          // }
        },
        onremotetrack: (track, mid, on) => {
          console.warn(track, mid, on);
        },
        mediaState: (medium, on, mid) => {
          console.log("===== MEDIASTATE: ", medium, on, mid);
          Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
        },
        slowLink: () => {
          console.log("slowLinkslowLinkslowLink");
        }
      });
    },
    attachSuccess() {
      this.showConnectVideoChatButton = true;
    },
    initDevices(devices) {
      this.devices = devices;

      const searchParams = new URLSearchParams(window.location.search);

      if (!devices || devices.length == 0) {
        this.isLoading = false;
      } else {
        if (searchParams.has("novideo")) {
          this.currentUser.hasVideo = false;
          this.selectedDevices = {};
          this.selectedDevices.audio = true;
          this.selectedDevices.video = false;
        } else {
          this.currentUser.hasVideo = true;
        }
        this.isLoading = false;
        this.attachSuccess();
      }
    },
    jsepProcessor(jsep) {
      this.conferenceConnection.handleRemoteJsep({ jsep: jsep });
    },
    checkUserMedia() {
      navigator.mediaDevices.getUserMedia({audio: true})
        .then(() => {
          this.successAudioCallback();
        })
        .catch(() => {
          this.errorAudioCallback();
        });
    },
    errorAudioCallback(e) {
      this.currentUser.hasVideo = false;
      this.currentUser.hasAudio = false;
      this.isLoading = false;
      console.error(e);
    },
    successAudioCallback() {
      this.currentUser.hasAudio = true;
      Janus.listDevices(this.initDevices);
    },
    joinToRoom() {
      let registerdata = {
        request: "join",
        room: this.roomid,
        ptype: "publisher",
        display: this.currentUser.encryptedKey,
      };

      this.conferenceConnection.send({ message: registerdata });
    },
    addParticipant(newpart) {
      console.log("TRACKLOG ------------addParticipant", newpart);
      let allids = [];
      for (let prt in this.participants) {
        allids.push(this.participants[prt]["id"]);
      }
      if (allids.indexOf(newpart["id"]) == -1) {
        // newpart.hasAudio = false;
        // newpart.hasVideo = false;
        // newpart.vertical = false;
        // newpart.hasAudio = true;
        // newpart.hasVideo = true;
        // newpart.vertical = false;
        let participantobj = {};
        participantobj["hasAudio"] = newpart.hasAudio || false;
        participantobj["hasVideo"] = newpart.hasVideo || false;
        participantobj["id"] = newpart.id;
        participantobj["display"] = newpart.display;
        participantobj["streams"] = newpart.streams;
        console.log("TRACKLOG ------------addParticipant ADDDING NEW ONE!", participantobj);
        this.participants.push(participantobj);
      }
    },

    sendNotificationJoinedRoom(){
      notifyJoined(this.roomid,this.currentUser.id);
    },

    eventProcessor(message) {
      let evt = message["videoroom"];
      console.log("TRACKLOG MESSAGEeventProcessoreventProcessor", message);
      if (evt == "joined") {
        console.log("Joined", message);
        this.myid = message["id"];
        mypvtid = message["private_id"];
        // if (this.selectedDevices && this.selectedDevices.audio && !this.selectedDevices.video) {
        //   this.publishOwnFeed(true, false);
        // } else {
        //   this.publishOwnFeed(true, true);
        // }
        // alert(this.currentUser.hasVideo);
        if (this.currentUser.hasVideo && this.currentUser.hasAudio){
          this.publishOwnFeed(true, true);
        }else if (this.currentUser.hasAudio && !this.currentUser.hasVideo){
          this.publishOwnFeed(true, false);
        }else if (!this.currentUser.hasAudio && this.currentUser.hasVideo){
          this.publishOwnFeed(false, true);
        }else if (!this.currentUser.hasAudio && !this.currentUser.hasVideo){
          this.publishOwnFeed(false,false);
        }
        this.currentUser.private_id = message["private_id"];
        this.currentUser.id = message.id;
        this.currentUser.joined = true;
        this.sendNotificationJoinedRoom();

        if (message["publishers"]) {
          console.log("TRACKLOG PUBLISHERS", message);
          if(message["publishers"].length > 0){
            this.addUsersToDiscussion(message["publishers"]);
          }
        }
        if(message["attendees"]){
          this.addUsersToDiscussion(message["attendees"]);
        }

        //setTimeout(() => this.getOccupants(this.roomid, message["publishers"]), 200);
      } else if (evt == "destroyed") {
        window.location.reload();
      } else if (evt == "event") {
        console.warn("TRACKLOG Event got ", message);
        if (message["publishers"]) {
          this.addUsersToDiscussion(message["publishers"]);
          // this.getOccupants(this.roomid, message["publishers"]);
        } else if (message["unpublished"]) {
          console.log("TRACK: UNPUBLISH", message);
          let unpublishid = message["unpublished"];
          if (unpublishid == "ok") {
            // this.conferenceConnection.hangup();
            return;
          }
          this.unsubscribeFrom(unpublishid);
          var index = this.participants.findIndex((obj) => {
            return obj.id == unpublishid;
          });
          console.log(index);
          this.removeStreamsOfUnpublished(unpublishid);
          if (index > -1) {
            this.participants.splice(index, 1);
          }
        } else if (message["joining"]) {
          //this.getOccupants(this.roomid);
        }
      }
    },
    removeStreamsOfUnpublished(unpublishid) {
      var streamsOfUnpublished = streamsIAlreadySubscribedTo.filter((element) => element.includes(unpublishid));

      for (let i = 0; i < streamsOfUnpublished.length; i++) {
        const element = streamsOfUnpublished[i];

        var index = streamsIAlreadySubscribedTo.indexOf(element);

        if (index > -1) {
          streamsIAlreadySubscribedTo.splice(index, 1);
        }
      }
    },

    hideVideoContainerOnMute(id){
      for(let partind in this.participants){
        if(this.participants[partind].id == id){
          // this.participants[partind].hasVideo = false;
          this.mutedVideo[id] = true;
          setTimeout(() => {
            if(this.mutedVideo[id] == true){
              this.participants[partind].hasVideo = false;
            }
          }, 1500);
        }
      }
    },
    showVideoContainerOnUnmute(id){
      for(let partind in this.participants){
        if(this.participants[partind].id == id){
          this.participants[partind].hasVideo = true;
          this.mutedVideo[id] = false;
        }
      }
    },

    
    unsubscribeFrom(id) {
      let unsubscribe = {
        request: "unsubscribe",
        streams: [{ feed: id }],
      };

      if (this.remoteFeed != null) {
        this.remoteFeed.send({ message: unsubscribe });
      }
    },
    publishOwnFeed(useAudio, useVideo) {
      let tracks = [];

      if (useAudio) {
        if (this.selectedDevices && this.selectedDevices.audio) {
          tracks.push({
            type: "audio",
            capture: { deviceId: { exact: this.selectedDevices.audio.deviceId } },
            recv: false,
          });
        } else {
          tracks.push({ type: "audio", capture: true, recv: false });
        }
      } else {
        tracks.push({ type: "audio", capture: false, recv: false });
      }

      if (useVideo) {
        if (this.selectedDevices && this.selectedDevices.video) {
          tracks.push({
            type: "video",
            capture: { deviceId: { exact: this.selectedDevices.video.deviceId } },
            recv: false,
          });
        } else {
          tracks.push({ type: "video", capture: true, recv: false, simulcast: true });
        }
      } else {
        tracks.push({ type: "video", capture: false, recv: false, simulcast: true });
      }

      if (!useVideo && !useAudio) {
        tracks = [{ type: "data" }];
      }

      this.conferenceConnection.createOffer({
        iceRestart: true,
        tracks: tracks,
        video: "lowres",
        success: (jsep) => {
          let publish = { request: "configure", audio: useAudio, video: useVideo };
          this.conferenceConnection.send({ message: publish, jsep: jsep });
        },
        error: (error) => {
          Janus.error("WebRTC error:", error);

          if (useAudio && useVideo) {
            this.publishOwnFeed(true, false);
            this.audioAndVideoDeniedBySystem = false;
          }

          if (useAudio && !useVideo) {
            this.publishOwnFeed(false, true);
            this.audioAndVideoDeniedBySystem = false;
          }

          if (!useAudio && !useAudio) {
            this.publishOwnFeed(false, false);

            if (error.indexOf("denied by system") > -1) {
              this.audioAndVideoDeniedBySystem = true;
            }
          }
        },
      });
    },

    addUsersToDiscussion(users) {
      let list = users;
      
      let participantsStreams = [];

      console.log("2 list list", list);

      for (let f in list) {
        console.log("LIST PARTICIPANTS: ", list[f]);

        this.addParticipant(list[f]);
        if (list[f]["dummy"]) {
          continue;
        }
        let id = list[f]["id"];
        let display = list[f]["display"];
        let streams = list[f]["streams"];
        console.log("track List streams", streams);
        for (let i in streams) {
          let stream = streams[i];
          stream["id"] = id;
          stream["display"] = display;
        }
        window.alltracks.push(streams);
        let slot = feedStreams[id] ? feedStreams[id].slot : null;
        let remoteVideos = feedStreams[id] ? feedStreams[id].remoteVideos : 0;
        feedStreams[id] = {
          id: id,
          display: display,
          streams: streams,
          slot: slot,
          remoteVideos: remoteVideos
        };
        if (streams) {
          // participantsStreams.push(streams);
          console.log("TRACKLOG STREAMS ", streams);
          // this.subscribeToPublisherStreams(streams,id);
          participantsStreams.push(streams);
        }
      }

      if (participantsStreams.length > 0) {
        this.subscribeTo(participantsStreams);
      }
    },

    getOccupants(roomid, publishers) {
      getRoomOccupants(roomid)
        .then((res) => {
          var allParticipants = res["plugindata"]["data"]["participants"] || [];

          if (allParticipants.length > 0) {
            var participants = allParticipants.filter((obj) => obj.id != this.currentUser.id);
            this.participants = participants.map((obj) => {
              obj.hasVideo = false;
              obj.videoDisabled = false;
              return obj;
            });
            console.log(publishers);
            // if (publishers) { 
            //   for (let i = 0; i < publishers.length; i++) {
            //     const publisher = publishers[i];

            //     // if (publisher.streams && publisher.streams.length > 0) {
            //     //   // this.subscribeToPublisherStreams(publisher.streams, publisher.id);
            //     // }
            //   }
            // }
          }
        })
        .catch((er) => {
          console.error(er);
        });
    },

    // subscribeTo(sources) {
    //   let streamsToSubscribe = [];
    //   for (let s in sources) {
    //     let streams = sources[s];

    //     for (let i in streams) {
    //       let stream = streams[i];
    //       streamsToSubscribe.push(stream);
    //     }
    //   }


    // },

    reattachVideoStreamIfEsists(str){
      console.log("========== reattachVideoStreamIfEsists", feedStreams[str.id]);
      let resultstrs = [];
      let strs = feedStreams[str.id]["streams"];
      for (let f in strs){
        console.log("&&&&&&&&&&&&&&&& 000", strs[f]);
        console.log("&&&&&&&&&&&&&&&& 111", strs[f]["type"]);
        console.log("&&&&&&&&&&&&&&&&2222 ", strs[f]["disabled"]);
        if(strs[f]["type"] == "video" && strs[f]["disabled"] != true){
          resultstrs.push(strs[f]);
        }
      }
      console.log("============ reattachVideoStreamIfEsists22222", resultstrs);
      console.log("============ reattachVideoStreamIfEsists22222", resultstrs.length);
      if(resultstrs.length > 0){
        let curstr = resultstrs[0];
        this.attachTrackToBox(curstr.id,"video",curstr.mid);
      }
    },

    subscribeTo(sources) {
      console.log("GOTSOURCES: ", sources);
      if(this.remoteFeed) {
        // Prepare the streams to subscribe to, as an array: we have the list of
        // streams the feeds are publishing, so we can choose what to pick or skip
        let added = null, removed = null;
        for(let s in sources) {
          let streams = sources[s];
          for(let i in streams) {
            let stream = streams[i];
            // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
            if(stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
              toastr.warning("Publisher is using " + stream.codec.toUpperCase +
                ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
              continue;
            }
            if(stream.disabled) {
              Janus.log("Disabled stream:", stream);
              // Unsubscribe
              if(!removed)
                removed = [];
              removed.push({
                feed: stream.id,	// This is mandatory
                mid: stream.mid		// This is optional (all streams, if missing)
              });
              let elemind = (parseInt(i) + 1);
              console.log("GOTSOURCES INDEX:", i, streams.length, elemind);
              if(elemind == streams.length){
                this.reattachVideoStreamIfEsists(stream);
              }
              console.warn("************ STREAMS: ", s, i);
              console.warn("================ STREAMS2", feedStreams[stream.id]);
              let slot = feedStreams[stream.id].slot;
              console.warn("+++++++++++ ##### SLOT: ", slot);
              delete subscriptions[stream.id][stream.mid];
              let midid = stream.id + "-" + slot + "-" + stream.mid;
              console.warn("++++++++ alreadyAttachedStreams", this.alreadyAttachedStreams, midid);
              if(this.alreadyAttachedStreams.indexOf(midid) > -1){
                let ind = this.alreadyAttachedStreams.indexOf(midid);
                this.alreadyAttachedStreams.splice(ind,1);
                console.warn("++++++++ alreadyAttachedStreams 22", this.alreadyAttachedStreams, ind);
              }
              // this.alreadyAttachedStreams
              continue;
            }
            console.warn("Subscriptions: ", subscriptions);
            console.warn("=========== Stream", stream);
            if(subscriptions[stream.id] && subscriptions[stream.id][stream.mid]) {
              Janus.log("Already subscribed to stream, skipping:", stream);
              continue;
            }
            // Find an empty slot in the UI for each new source
            if(!feedStreams[stream.id].slot) {
              let slot;
              for(let i=1;i<6;i++) {
                if(!feeds[i]) {
                  slot = i;
                  feeds[slot] = stream.id;
                  feedStreams[stream.id].slot = slot;
                  feedStreams[stream.id].remoteVideos = 0;
                  //$('#remote' + slot).removeClass('hide').html(escapeXmlTags(stream.display)).show();
                  break;
                }
              }
            }
            // Subscribe
            if(!added)
              added = [];
            added.push({
              feed: stream.id,	// This is mandatory
              mid: stream.mid		// This is optional (all streams, if missing)
            });
            console.log("GOTSOURCES added: ", added);
            if(!subscriptions[stream.id]){
              subscriptions[stream.id] = {};
            }
            subscriptions[stream.id][stream.mid] = true;
            console.log("GOTSOURCES subscriptions: ", subscriptions);
          }
        }
        if((!added || added.length === 0) && (!removed || removed.length === 0)) {
          // Nothing to do
          return;
        }
        let update = { request: "update" };
        if(added)
          update.subscribe = added;
        if(removed)
          update.unsubscribe = removed;
        console.log("GOTSOURCES SUBSCRIBE ", update);
        console.warn("Subscribe 1 time", update);
        this.remoteFeed.send({ message: update });
        // Nothing else we need to do
        return;
      }

      let streamsToSubscribe = [];

      for (let s in sources) {
        let streams = sources[s];

        for (let i in streams) {
          let stream = streams[i];
          streamsToSubscribe.push(stream);
        }
      }
      // console.log("subscribeStreams", streamsToSubscribe);
      this.janus.attach(
        {
          plugin: "janus.plugin.videoroom",
          opaqueId: opaqueId,
          success: (pluginHandle) => {
            let actionWithSubscription = "create";
            if (!this.remoteFeed) {
              this.remoteFeed = pluginHandle;
            }
            let subscription = [];
            let added = null, removed = null;

            for (let i in streamsToSubscribe) {
              let stream = streamsToSubscribe[i];

              console.log("Going to Subscribe to: ", + stream.id + "/" + stream.mid + "?", subscriptions);
              Janus.log("Subscribed to " + stream.id + "/" + stream.mid + "?", subscriptions);

              let streamIdMid = `${stream.id}_${stream.mid}`;
              
              console.log("Stream subscribe MID: ", streamIdMid);

              if (streamsIAlreadySubscribedTo.indexOf(streamIdMid) == -1) {
                streamsIAlreadySubscribedTo.push(streamIdMid);
                if (!feedStreams[stream.id].slot) {
                  let slot;
                  for (let i = 1; i < 6; i++) {
                    if (!feeds[i]) {
                      slot = i;
                      feeds[slot] = stream.id;
                      feedStreams[stream.id].slot = slot;
                      feedStreams[stream.id].remoteVideos = 0;
                      break;
                    }
                  }
                }
                subscription.push({
                  feed: stream.id,
                  mid: stream.mid	
                });

                if (!subscriptions[stream.id]) {
                  subscriptions[stream.id] = {};
                }
                subscriptions[stream.id][stream.mid] = true;
              } else {
                actionWithSubscription = "update";

                if (stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                  (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
                  continue;
                }

                if (stream.disabled) {
                  Janus.log("Disabled stream:", stream);

                  if (!removed) {
                    removed = [];
                  }
                  removed.push({
                    feed: stream.id,
                    mid: stream.mid
                  });
                  delete subscriptions[stream.id][stream.mid];
                  continue;
                }

                if (subscriptions[stream.id] && subscriptions[stream.id][stream.mid]) {
                  Janus.log("Already subscribed to stream, skipping:", stream);
                  continue;
                }

                if (!feedStreams[stream.id].slot) {
                  let slot;

                  for (let i = 1; i < 6; i++) {
                    if (!feeds[i]) {
                      slot = i;
                      feeds[slot] = stream.id;
                      feedStreams[stream.id].slot = slot;
                      feedStreams[stream.id].remoteVideos = 0;
                      break;
                    }
                  }
                }

                if (!added) {
                  added = [];
                }

                added.push({
                  feed: stream.id,
                  mid: stream.mid
                });

                if (!subscriptions[stream.id]){
                  subscriptions[stream.id] = {};
                }

                subscriptions[stream.id][stream.mid] = true;
              }
            }

            if (actionWithSubscription == "create") {
              remoteTracks = {};
              Janus.log("  -- This is a multistream subscriber");

              let subscribe = {
                request: "join",
                room: myroom,
                ptype: "subscriber",
                streams: subscription,
                use_msid: use_msid,
                private_id: mypvtid
              };

              if (aleradyJoinedAtFirstTime) {
                subscribe = {
                  request: "subscribe",
                  streams: subscription
                };
              }

              aleradyJoinedAtFirstTime = true;
              console.log ("Subscribe remote feed -- Create: ", subscribe);
              console.warn("Subscribe 2 time", subscribe);
              this.remoteFeed.send({ message: subscribe });
            } else if (actionWithSubscription == "update") {
              if ((!added || added.length === 0) && (!removed || removed.length === 0)) {
                return;
              }

              let update = { request: "update" };

              if (added){
                update.subscribe = added;
              }

              if (removed){
                update.unsubscribe = removed;
              }
              console.log ("Subscribe remote feed -- Update: ", update);
              console.warn("Subscribe 3 time", update);
              this.remoteFeed.send({ message: update });
            }
          },
          error: (error) => {
            console.error(error);
          },
          iceState: (state) => {
            Janus.log("ICE state (remote feed) changed to " + state);
          },
          webrtcState: (on) => {
            Janus.log("Janus says this WebRTC PeerConnection (remote feed) is " + (on ? "up" : "down") + " now");
          },
          slowLink: (uplink, lost, mid) => {
            Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
              " packets on mid " + mid + " (" + lost + " lost packets)");
          },
          onmessage: (msg, jsep) => {
            Janus.debug(" ::: Got a message (subscriber) :::", msg);
            let event = msg["videoroom"];
            Janus.debug("Event: " + event);

            if (msg["error"]) {
              console.error(msg);
            } else if (event) {
              if (event === "attached") {
                Janus.log("Successfully attached to feed in room " + msg["room"]);
              } else if (event === "event") {
                let mid = msg["mid"];
                let substream = msg["substream"];
                let temporal = msg["temporal"];

                if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                  let sub = subStreams[mid];
                  let feed = feedStreams[sub.feed_id];
                  let slot = slots[mid];
                  console.log(feed);
                  console.log(slot);
                }
              } 
            }

            if (msg["streams"]) {
              for (let i in msg["streams"]) {
                let mid = msg["streams"][i]["mid"];
                subStreams[mid] = msg["streams"][i];
                let feed = feedStreams[msg["streams"][i]["feed_id"]];

                if (feed && feed.slot) {
                  slots[mid] = feed.slot;
                  mids[feed.slot] = mid;
                }
              }
            }

            if (jsep) {
              Janus.debug("Handling SDP as well...", jsep);
              this.remoteFeed.createAnswer(
                {
                  jsep: jsep,
                  tracks: [
                    { type: "data" }
                  ],
                  success: (jsep) => {
                    Janus.debug("Got SDP!");
                    Janus.debug(jsep);
                    let body = { request: "start", room: myroom };
                    this.remoteFeed.send({ message: body, jsep: jsep });
                  },
                  error: (error) => {
                    Janus.error("WebRTC error:", error);
                  }
                });
            }
          },
          onlocaltrack: (track, on) => {
            console.log(track);
            console.log(on);
          },
          onstreamadded: () => {
            alert("remote track addd");
          },
          onremotetrack: (track, mid, on, metadata) => {
            console.log("onremotetrackonremotetrackonremotetrack: ",track, mid, on, metadata);
            console.log("REMOTETRACKGOTONLY: ",track, mid, on, metadata);
            let objIndex = null;
            let slot = slots[mid];
            let sub = subStreams[mid];
            console.log("OPLOG: ", slots, subStreams, feedStreams);
            let feed = feedStreams[sub.feed_id];

            if(feed){
              for(let obji in this.participants ){
                let objUser = this.participants[obji];
                if(objUser && objUser["id"] && feed){
                  if(objUser["id"] == feed["id"]){
                    objIndex = obji;
                  }
                }
              }
            }

            if(!on){
              console.log("======================= ", remoteTracks);
              delete remoteTracks[mid];
              delete slots[mid];

              if(feed && objIndex){
                // let remotevideobox = document.querySelector("#videobox-" + feed["id"]);
                // let sourceobj = remotevideobox.srcObject;
                // let existingtrack = sourceobj.getVideoTracks()[0];
                let objuser = this.participants[objIndex];
                objuser.hasVideo = false;
                // if(existingtrack){
                //   //existingtrack.stop();
                // }
              }
              return;
            }
            console.log("REMOTETRACK alreadyAttachedStreams: ",this.alreadyAttachedStreams, feed);

            if (this.alreadyAttachedStreams.indexOf(feed["id"]+"-"+slot+"-"+mid) > -1) {
              return;
            }
            if(slot){
              this.alreadyAttachedStreams.push(feed["id"]+"-"+slot+"-"+mid);
            }
            //Janus.debug(" >> This track is coming from feed " + sub.feed_id + ":", feed);
            console.log("REMOTETRACK: feed ",feed, slot);
            if (feed && !slot) {
              slot = feed.slot;
              slots[mid] = feed.slot;
              mids[feed.slot] = mid;
            }
            //Janus.debug(" >> mid " + mid + " is in slot " + slot);

            if (feed && !on) {
              if (track.kind === "audio") {
                if (this.participants[objIndex]) {
                  this.participants[objIndex].hasAudio = false;
                }
              } else {
                if (this.participants[objIndex]) {
                  this.participants[objIndex].hasVideo = false;
                }
              }

              delete remoteTracks[mid];
              delete slots[mid];
              delete mids[slot];
              return;
            }

            if(!this.userStreams[feed.id]){
              this.userStreams[feed.id] = {};
              this.userStreams[feed.id]["video"] = {};
              this.userStreams[feed.id]["audio"] = {};
            }

            if (track.kind === "audio") {
              let stream = new MediaStream([track]);
              window.remoteAudio = stream;
              remoteTracks[mid] = stream;
              Janus.log("Created remote audio stream:", stream);

              this.userStreams[feed.id]["audio"][mid] = stream;
              
              this.attachTrackToBox(feed["id"],"audio",mid);
              // setTimeout(() => {
              // }, 500);

              if (feed.remoteVideos === 0) {
                console.log("No remote videos");
              }
              this.participants[objIndex].hasAudio = true;
              this.participants[objIndex].audioDisabled = false;
            } else {
              feed.remoteVideos++;

              track.onmute = () => {
                this.hideVideoContainerOnMute(feed["id"]);
              };

              track.onunmute = () => {
                this.showVideoContainerOnUnmute(feed["id"]);
              };
              
              console.log("RemoteTrackDebug tracktracktrack", track);
              console.log("RemoteTrackDebug tracktracktrack ALLL", this.userStreams);

              let stream = new MediaStream([track]);
              window.allstreams.push(stream);
              //this.userStreams[feed.id]["video"] = stream;
              this.userStreams[feed.id]["video"][mid] = stream;

              remoteTracks[mid] = stream;
              console.log("RemoteTrackDebug remoteTracks---- ", remoteTracks);
              this.attachTrackToBox(feed["id"],"video",mid);
              console.log("participantsparticipantsparticipants", this.participants[objIndex]);
              this.participants[objIndex].hasVideo = true;
              this.participants[objIndex].videoDisabled = false;
            }
          },
          oncleanup: () => {
            Janus.log(" ::: Got a cleanup notification (remote feed) :::");

            for (let i = 1; i < 6; i++) {
              feedStreams[i].simulcastStarted = false;
              feedStreams[i].remoteVideos = 0;
            }
            remoteTracks = {};
          }
        });
    },

    attachTrackToBox(id,type,mid) {
      console.warn("ATTACHING: ", id,type,mid);
      console.log("=================== ATTACHING ID: ", id);
      // if(this.alreadyAttachedStreams.indexOf(id+type) > -1){
      //   console.log("++++++++++++++++++++ ALREADY ID EXISTS: ", id);
      //   return;
      // }
      // this.alreadyAttachedStreams.push(id+type);
      console.log("RemoteTrackDebug Streams", this.userStreams);
      console.log("RemoteTrackDebug ATTACHING ID: ", id, type, mid);
      console.log("RemoteTrackDebug ALL STREAMS ", this.userStreams);
      // console.log("TRACKLOG ATTACH TO VIDEOBOX: ", id, type);
      let remotebox = null;
      if (type == "video") {
        remotebox = document.querySelector("#videobox-" + id);
      }
      if (type == "audio") {
        remotebox = document.querySelector("#audiobox-" + id);
      }
      //console.log("TRACKLOG ATTACH TO VIDEOBOX  REMOTEBOX: ", remotebox);

      // console.log("TRK Attachef STREAMS", this.userStreams);
      console.log("RemoteTrackDebug, ", remotebox.srcObject);
      
      setTimeout(() => {
        Janus.attachMediaStream(remotebox, this.userStreams[id][type][mid]);
      }, 200);
    },
    onJoinToRoom(data) {
      this.selectedDevices = data;
      this.joinToRoom();
    },
    leaveRoom() {
      window.parent.postMessage("consultationEnded", "*");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    createMuteVideoOffer() {
      let tracks = [];
      this.conferenceConnection.createOffer({
        iceRestart: true,
        tracks: tracks,
        success: (jsep) => {
          this.conferenceConnection.send({ message: { request: "configure", video: false }, jsep: jsep });
        },
        error: (error) => {
          this.logError(error);
        },
      });
    },
    createUnmuteVideoOffer() {
      let tracks = [];

      if (this.selectedDevices && this.selectedDevices.video) {
        tracks.push({
          type: "video",
          capture: { deviceId: { exact: this.selectedDevices.video.deviceId } },
          recv: false,
        });
      } else {
        tracks.push({ type: "video", capture: true, recv: false });
      }

      this.conferenceConnection.createOffer({
        iceRestart: true,
        tracks: tracks,
        success: (jsep) => {
          this.conferenceConnection.send({ message: { request: "configure", video: true }, jsep: jsep });
        },
        error: (error) => {
          this.logError(error);
        },
      });
    },
    muteUnmuteVideo() {
      if (this.conferenceConnection.isVideoMuted()) {
        this.createUnmuteVideoOffer();
        this.conferenceConnection.unmuteVideo();
        this.currentUser.hasVideo = true;
      } else {
        this.createMuteVideoOffer();
        this.conferenceConnection.muteVideo();
        this.currentUser.hasVideo = false;
      }
    },
    muteUnmuteAudio() {
      if (this.conferenceConnection.isAudioMuted()) {
        this.conferenceConnection.unmuteAudio();
        this.currentUser.hasAudio = true;
      } else {
        this.conferenceConnection.muteAudio();
        this.currentUser.hasAudio = false;
      }
    },
    onStopScreenShare() {
      // console.log("LOG ", "onStopScreenShare", this.conferenceConnection.isVideoMuted(), this.currentUser);
      alert("Stop Screen Sharing");
    },
    logError(error) {
      console.error("Error: ", error);
    },
    errorCallback(e) {
      this.currentUser.hasVideo = false;

      console.error(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/confermall/confermall-main.scss";

%textureBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  //height: 100%;
  content: "";
  background: transparent url("../assets/images/confermall/texture-small.png") left top repeat;
}

.confermall-chat-page {
  font-family: var(--font-family);
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 320px;
  height: 100%;
  background: #1a2432;

  justify-content: center;
  &.height-auto {
    height: auto;
    min-height: 100%;
  }
}


.current-user-videobox {
  position: fixed;
  top: 48px;
  left: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 140px;
  height: 105px;
  border-radius: 12px;
  background-color: $gray-700;

  justify-content: center;

  @media (min-width: 542px) {
    top: 64px;
    left: 16px;
  }

  @media (min-width: 768px) {
    top: auto;
    bottom: 16px;
    left: 16px;
  }
  &-no-video {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $brand-100;

    justify-content: center;
  }
  &-video {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

#current-user-video {
  -webkit-transform: scale(-1, 1);
     -moz-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
       -o-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.current-user-actions {
  position: fixed;
  z-index: 9;
  right: 0;
  bottom: 32px;
  left: 0;
  display: flex;

  gap: 16px;
  justify-content: center;

  @media (min-width: 768px) {
    bottom: 48px;

    gap: 32px;
  }
}

.alert-info {
  max-width: calc(100% - 32px);
  margin: 0 16px;
  text-align: center;
  @media (min-width: 768px) {
    max-width: 640px;
  }
}

.user-avatar-loader {
  background: transparent url("../assets/images/confermall/avatar.svg") center center no-repeat;
}

.b-no-participants {
  padding-bottom: 80px;

  @media (min-width: 768px) {
    padding-bottom: 112px;
  }
}

video{
  border-radius: 12px;
}
</style>
